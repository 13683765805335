import React from "react";
import PropTypes from "prop-types";

const Avatar = ({ name, email }) => <span>{name || email}</span>;
Avatar.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
};
Avatar.defaultProps = {
  name: "",
  email: "",
};

export default Avatar;
