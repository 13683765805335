import React, { useState, useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useForm } from "react-hook-form";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LockResetIcon from "@mui/icons-material/LockReset";
import SettingsPhoneIcon from "@mui/icons-material/SettingsPhone";
import LogoutIcon from "@mui/icons-material/Logout";

import UserAvatar from "components/common/UserAvatar";
import { useAuth } from "hooks/useProvideAuth";
import { SIGN_OUT_DELAY } from "utils/constants";
import { PrimaryButton, GhostButton } from "components/common/Buttons";
import { PageFooter } from "components/common/Wrapper";
import useConfirm from "hooks/useConfirm";
import logoNoText from "assets/svg/mega_portal_logo_no_text.svg";
import { changePassword, updateMfaStatus, updateUserSetting } from "api/UserSettingHelpers";
import { showError, showSuccess, showWarning } from "utils/notifications";
import { passwordStrengthCheck } from "utils/util-functions";
import { FieldError, FormInput, PhoneInputWrapper } from "components/common/Input";
import { GlobalContext } from "context/GlobalContext";

import {
  MenuBarWrapper,
  DrawerButton,
  MailLink,
  ProfileContent,
  AvatarButton,
  MFASwitch,
  ContentRow,
  TitleWrapper,
  NewPasswordForm,
  MenuBarContent,
} from "./Menubar.style";

const Menubar = ({ toggleDrawer }) => {
  const {
    name,
    email,
    signOut,
    refetch,
    phone_number: currPhoneNumber,
    profile: organizationName,
    roleName,
    isMFAEnabled: mfaEnabled,
    sub: userId,
  } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState(currPhoneNumber);

  const { isDirectFromLogin, setIsDirectFromLogin } = useContext(GlobalContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({});
  const newPassword = watch("newPassword", "");

  const [getConfirmation, Confirmation] = useConfirm();
  const [isOpenProfileModal, setIsOpenProfileModal] = useState(false);
  const [isOpenPasswordModal, setIsOpenPasswordModal] = useState(false);
  const [isMFAEnabled, setIsMFAEnabled] = useState(mfaEnabled);
  const [isSavingSetting, setIsSavingSetting] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const organizationTitle = useMemo(() => organizationName?.replace("-", " "), [organizationName]);

  useEffect(() => {
    if (mfaEnabled !== undefined) {
      setIsMFAEnabled(mfaEnabled);
    }
  }, [mfaEnabled]);

  const onClickAvatar = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (menuItem) => {
    setAnchorEl(null);
    if (menuItem === "logout") {
      await signOut();
    }
  };

  const onToggleMFA = (event) => {
    const { target } = event;
    setIsMFAEnabled(target.checked);
  };

  const onModalClose = () => {
    setIsOpenProfileModal(false);
  };

  const onPasswordModalClose = () => {
    setIsOpenPasswordModal(false);
  };

  const showProfileModal = () => {
    refetch();
    setIsMFAEnabled(mfaEnabled);
    setPhoneNumber(currPhoneNumber);
    setAnchorEl(null);
    setIsOpenProfileModal(true);
  };

  const onChangePassword = () => {
    setAnchorEl(null);
    setIsOpenPasswordModal(true);
    setValue("currPassword");
    setValue("newPassword");
    setValue("confirmNewPassword");
  };

  const saveUserSetting = async () => {
    // if phone number is not valid
    if (!phoneNumber || !isPossiblePhoneNumber(phoneNumber)) {
      return;
    }

    if (phoneNumber === currPhoneNumber && mfaEnabled === isMFAEnabled) {
      showWarning("Nothing changed!");
      return;
    }

    try {
      let updateMFA = false;
      let updatePhoneNumber = false;

      // if phone number is changed
      if (phoneNumber !== currPhoneNumber) {
        const isConfirm = await getConfirmation({
          title: "Phone Number Change",
          content: "Are you sure to update the phone number?",
        });

        if (isConfirm) {
          updatePhoneNumber = true;
        }
      }

      // if MFA setting is changed
      if (mfaEnabled !== isMFAEnabled) {
        const isConfirm = await getConfirmation({
          title: "MFA Setting Confirmation",
          content: `To ${isMFAEnabled ? "enable" : "disable"} MFA, you will be logged out. Would you like to continue?`,
        });

        if (isConfirm) {
          updateMFA = true;
        }
      }

      setIsSavingSetting(true);

      if (updateMFA) {
        await updateMfaStatus({ userEmail: email, mfaEnabled: isMFAEnabled.toString() });
        showSuccess(`MFA setting is ${isMFAEnabled ? "enabled" : "disabled"} now!`);
      }
      if (updatePhoneNumber) {
        await updateUserSetting({ username: userId, email, phonenumber: phoneNumber });
        showSuccess(
          "Updated phone number successfully! You need to log out and log in again to see changes on the phone number.",
        );
      }

      setIsOpenProfileModal(false);
      if (updateMFA) {
        setTimeout(async () => {
          await signOut();
        }, SIGN_OUT_DELAY);
      }
    } catch (e) {
      // error handling
    } finally {
      setIsSavingSetting(false);
    }
  };

  const onFormSubmit = async (data) => {
    const { currPassword, newPassword: newPwd } = data;

    const accessToken = localStorage.getItem("access");
    if (!accessToken) {
      showError("Access token is not valid!");
      return;
    }

    const body = {
      username: userId,
      usertoken: accessToken,
      oldpassword: currPassword,
      newpassword: newPwd,
    };

    setIsChangingPassword(true);
    try {
      const { data: res } = await changePassword(body);
      showSuccess(res?.message);
      setIsOpenPasswordModal(false);
    } catch (error) {
      // error handling
    } finally {
      setIsChangingPassword(false);
    }
  };

  useEffect(() => {
    if (isDirectFromLogin) {
      setIsDirectFromLogin(false);
    }
  }, []);

  return (
    <>
      <MenuBarWrapper>
        <MenuBarContent>
          <DrawerButton onClick={() => toggleDrawer?.()}>
            <MenuIcon style={{ color: theme.palette.common.white }} />
          </DrawerButton>

          <TitleWrapper>
            <img src={logoNoText} alt="logo" style={{ height: 30 }} />
            <div className="title-text">{`Mega Portal - ${organizationTitle}`}</div>
          </TitleWrapper>

          <AvatarButton onClick={onClickAvatar}>
            <PersonOutlineIcon style={{ marginRight: 4 }} />
            <UserAvatar name={name} email={email} />
            <ArrowDropDownIcon style={{ marginBottom: 2 }} />
          </AvatarButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            disableScrollLock
          >
            <MenuItem onClick={showProfileModal}>
              <ListItemIcon>
                <PersonOutlineIcon />
              </ListItemIcon>
              <ListItemText>User Profile</ListItemText>
            </MenuItem>
            <MenuItem onClick={onChangePassword}>
              <ListItemIcon>
                <LockResetIcon />
              </ListItemIcon>
              <ListItemText>Change Password</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => setAnchorEl(null)}>
              <MailLink href="mailto:support@nextivityinc.com">
                <ListItemIcon>
                  <SettingsPhoneIcon />
                </ListItemIcon>
                <ListItemText>Contact Support</ListItemText>
              </MailLink>
            </MenuItem>
            <MenuItem onClick={() => handleClose("logout")}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </MenuBarContent>
      </MenuBarWrapper>

      <Dialog fullWidth maxWidth="xs" open={isOpenPasswordModal} onClose={onPasswordModalClose}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <NewPasswordForm>
            <FormInput
              type="password"
              placeholder="Current Password"
              {...register("currPassword", {
                required: "Current Password is required!",
              })}
              error={errors.currPassword}
            />
            {errors.currPassword && <FieldError>{errors.currPassword.message}</FieldError>}

            <FormInput
              type="password"
              placeholder="New Password"
              {...register("newPassword", {
                required: "New Password is required!",
                validate: {
                  passwordStrengthCheck,
                },
              })}
              style={{ marginTop: 15 }}
              error={errors.newPassword}
            />
            {errors.newPassword && <FieldError>{errors.newPassword.message}</FieldError>}

            <FormInput
              type="password"
              placeholder="Confirm New Password"
              {...register("confirmNewPassword", {
                required: "Confirm New Password is required!",
                validate: (value) => value === newPassword || "Passwords do not match!",
              })}
              style={{ marginTop: 15 }}
              error={errors.confirmNewPassword}
            />
            {errors.confirmNewPassword && <FieldError>{errors.confirmNewPassword.message}</FieldError>}

            <PageFooter>
              <PrimaryButton onClick={handleSubmit(onFormSubmit)} loading={isChangingPassword}>
                Change
              </PrimaryButton>
              <GhostButton onClick={() => setIsOpenPasswordModal(false)} sx={{ ml: 2 }}>
                Cancel
              </GhostButton>
            </PageFooter>
          </NewPasswordForm>
        </DialogContent>
      </Dialog>

      <Dialog fullWidth maxWidth="xs" open={isOpenProfileModal} onClose={onModalClose}>
        <DialogTitle>User Profile</DialogTitle>
        <DialogContent>
          <ProfileContent>
            <ContentRow>
              <span className="label">Full Name:</span>
              <span className="value">{name}</span>
            </ContentRow>
            <ContentRow>
              <span className="label">Email:</span>
              <span className="value">{email}</span>
            </ContentRow>
            <ContentRow>
              <span className="label">Mobile Phone Number:</span>
              <span className="value">
                <PhoneInputWrapper
                  international
                  defaultCountry="US"
                  countryCallingCodeEditable={false}
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e);
                  }}
                />
                <FieldError>
                  {phoneNumber
                    ? isPossiblePhoneNumber(phoneNumber)
                      ? undefined
                      : "Phone number is not valid!"
                    : "Phone number is required!"}
                </FieldError>
              </span>
            </ContentRow>
            <ContentRow>
              <span className="label">Organization Name:</span>
              <span className="value">{organizationName}</span>
            </ContentRow>
            <ContentRow>
              <span className="label">Role Name:</span>
              <span className="value">{roleName}</span>
            </ContentRow>
            <ContentRow>
              <span className="label">MFA:</span>
              <FormControlLabel
                control={<MFASwitch defaultChecked={isMFAEnabled} />}
                label={isMFAEnabled ? "Enabled" : "Disabled"}
                onChange={onToggleMFA}
              />
            </ContentRow>
          </ProfileContent>

          <PageFooter>
            <PrimaryButton onClick={saveUserSetting} loading={isSavingSetting}>
              Save
            </PrimaryButton>
            <GhostButton onClick={() => setIsOpenProfileModal(false)} sx={{ ml: 2 }}>
              Cancel
            </GhostButton>
          </PageFooter>
        </DialogContent>
      </Dialog>

      <Confirmation />
    </>
  );
};
Menubar.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
};

export default Menubar;
