import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import BREAKPOINTS from "utils/breakpoints";

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  flex-shrink: 0;
  padding: 20px 0px;
  z-index: 5;
  background-color: ${({ theme }) => theme.palette.text.primary};
  box-shadow: 2px 0px 5px ${({ theme }) => theme.palette.gray[200]};

  @media screen and (max-width: 700px) {
    padding: 2rem 4rem;
  }

  @media screen and (max-width: 550px) {
    padding: 2rem;
  }

  ${BREAKPOINTS.md} {
    display: none;
  }
`;

export const DrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 230px;
  background-color: ${({ theme }) => theme.palette.text.primary};
  padding: 20px 0px;
`;

export const SidebarLinks = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
  }

  .search-link {
    font-weight: 500;
    font-size: 18px;
    font-family: var(--font-primary);
    color: ${({ theme }) => theme.palette.common.white};
  }

  .awcone__navbar-links_logo img {
    width: 150px;
    height: 30px;
  }

  .awcone__navbar-links_container {
    display: flex;
    flex-direction: column;
    width: 100%;

    a {
      padding: 10px 15px;
      margin: 0px;

      &:hover {
        background-color: ${({ theme }) => theme.palette.gray[500]};
      }

      &.active {
        background-color: ${({ theme }) => theme.palette.gray[500]};
        color: ${({ theme }) => theme.palette.common.white};
      }
    }
  }

  .version-number {
    color: ${({ theme }) => theme.palette.common.white};
    text-align: center;
    font-size: 12px;
  }
`;

export const NavbarSign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 550px) {
    display: none;
  }
`;

export const NavbarMenu = styled.div`
  margin-left: 1rm;
  display: none;
  position: relative;

  svg {
    cursor: pointer;
  }

  @media screen and (max-width: 1050px) {
    display: flex;
  }
`;

export const NavbarMenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: ${({ theme }) => theme.palette.gray[200]};
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

  .awcone__navbar-menu_container-links-sign {
    display: none;
  }

  @media screen and (max-width: 550px) {
    top: 20px;

    .awcone__navbar-menu_container-links-sign {
      display: block;
    }
  }
`;

export const SidebarLink = styled(NavLink)`
  color: ${({ theme }) => theme.palette.gray[300]};
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
`;
