import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Drawer, IconButton, TextField, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DisplaySettingsOutlined from "@mui/icons-material/DisplaySettingsOutlined";
import { TextButton } from "components/common/Buttons";

import { PageHeader } from "components/common/Wrapper";
import { useRequestInfinite } from "hooks/useRequest";
import usePrevious from "hooks/usePrevious";
import style from "./map-side-bar.module.css";
import { SidebarWrapper, DrawerWrapper, DeviceTreeView, ContentWrapper, Spinner } from "./MapSidebar.style";
import DeviceList from "./DeviceList.component";
import { POOL_LIST_PAGE_SIZE, AVAILABLE_KEY_FIELDS, KEY_FIELD_IDS } from "./MapSidebar.config";
import { CheckableLabel, TreeIconItem } from "./ItemLabel.component";

const MapSidebarContent = ({ onDeviceChange, initialChecked, onDeviceLoaded, dataFieldStr }) => {
  const [checkedDeviceList, setCheckedDeviceList] = useState(initialChecked || []);
  const [checkedPoolList, setCheckedPoolList] = useState([]);
  const [keyField, setKeyField] = useState(KEY_FIELD_IDS.SERIAL_NO);
  const [poolArray, setPoolArray] = useState([]);
  const prevCheckedPoolList = usePrevious(checkedPoolList);
  const [displayKeyField, setDisplayKeyField] = useState(false);

  const { data, size, setSize, isLoading } = useRequestInfinite((index) => {
    const startIndex = POOL_LIST_PAGE_SIZE * index;
    const endIndex = startIndex + POOL_LIST_PAGE_SIZE - 1;
    return `/beta/devicePools?firstDevicePoolIndex=${startIndex}&lastDevicePoolIndex=${endIndex}`;
  });

  const isLoadingMore = isLoading || (size > 0 && data && typeof data[size - 1] === "undefined");

  const isReachingEnd = useMemo(() => {
    if (!data || !data.length) return true;

    return data[0].totalDevicePoolsInOrg <= poolArray.length;
  }, [poolArray]);

  useEffect(() => {
    if (!data || !data.length) {
      setPoolArray([]);
    } else {
      let mergedArray = [];
      data.forEach(({ devicePoolData }) => {
        mergedArray = mergedArray.concat(devicePoolData || []);
      });

      setPoolArray(mergedArray);
    }
  }, [data]);

  const onCheckNode = (checked, nodeId, deviceCheck = true) => {
    if (deviceCheck) {
      setCheckedDeviceList((checkedList) => {
        const newList = checkedList.filter((item) => item !== nodeId);

        if (checked) {
          newList.push(nodeId);
        }

        onDeviceChange?.(newList);

        return newList;
      });
    } else {
      setCheckedPoolList((poolList) => {
        const newList = poolList.filter((item) => item !== nodeId);

        if (checked) {
          newList.push(nodeId);
        }

        return newList;
      });
    }
  };

  const onKeyFieldChange = (event) => {
    setKeyField(event.target.value);
  };

  return (
    <ContentWrapper>
      <PageHeader>
        <span className="header-title">Device List</span>
        <IconButton color="primary" onClick={() => setDisplayKeyField(!displayKeyField)}>
          <DisplaySettingsOutlined />
        </IconButton>
      </PageHeader>

      {displayKeyField && (
        <div className={style.formWrapper}>
          <FormControl sx={{ m: 0, width: "100%" }} size="small">
            <TextField
              labelId="key-field-label"
              size="small"
              id="select-key-field"
              className={style.textField}
              label={
                <Typography variant="headline" className={style.textFieldLabel} component="h3">
                  Display Value
                </Typography>
              }
              value={keyField}
              onChange={onKeyFieldChange}
              InputLabelProps={{ style: { fontSize: "14px" } }}
              select
            >
              {AVAILABLE_KEY_FIELDS.map(({ id, label }) => (
                <MenuItem key={`${label}${id}`} className={style.menuItem} value={id}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </div>
      )}

      <DeviceTreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
        {poolArray.map(({ devicePool }) => (
          <TreeIconItem
            className="pool-node"
            key={devicePool}
            nodeId={`pool-${devicePool}`}
            label={
              <CheckableLabel
                title={devicePool}
                onCheck={(e) => onCheckNode(e.target.checked, devicePool, false)}
                isChecked={checkedPoolList.includes(devicePool)}
              />
            }
          >
            <DeviceList
              poolId={devicePool}
              onCheckDevice={onCheckNode}
              checkedDeviceList={checkedDeviceList}
              onDeviceLoaded={onDeviceLoaded}
              dataFieldStr={dataFieldStr}
              checkedPoolList={checkedPoolList}
              prevCheckedPoolList={prevCheckedPoolList}
              keyField={keyField}
            />
          </TreeIconItem>
        ))}

        {isLoadingMore ? (
          <TreeItem icon={false} nodeId={`loading-${Math.random().toString(36).slice(2)}`} label={<Spinner />} />
        ) : isReachingEnd ? (
          <div />
        ) : (
          <div className={style.loadButtonContainer}>
            <TextButton
              nodeId={`loading-${Math.random().toString(36).slice(2)}`}
              onClick={() => setSize(size + 1)}
              className={style.loadMores}
            >
              Load More...
            </TextButton>
          </div>
        )}
      </DeviceTreeView>
    </ContentWrapper>
  );
};
MapSidebarContent.propTypes = {
  onDeviceChange: PropTypes.func.isRequired,
  initialChecked: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDeviceLoaded: PropTypes.func.isRequired,
  dataFieldStr: PropTypes.string.isRequired,
};

const MapSidebar = ({ showDrawer, onCloseDrawer, ...rest }) => (
  <div>
    <SidebarWrapper>
      <MapSidebarContent {...rest} />
    </SidebarWrapper>

    <Drawer
      anchor="right"
      open={showDrawer}
      onClose={() => onCloseDrawer?.()}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <DrawerWrapper>{/* <MapSidebarContent {...rest} /> */}</DrawerWrapper>
    </Drawer>
  </div>
);
MapSidebar.propTypes = {
  showDrawer: PropTypes.bool.isRequired,
  onCloseDrawer: PropTypes.func.isRequired,
  setIsMapDataLoading: PropTypes.func.isRequired,
};

export default MapSidebar;
