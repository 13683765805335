import useSwr from "swr";
import useSWRInfinite from "swr/infinite";

import axiosInst from "api/config/axiosConfig";
import { ERROR_RETRY_COUNT } from "utils/constants";

const fetcher = (url) => axiosInst.get(url).then((res) => res.data);

const useRequest = (path) => {
  if (!path) {
    throw new Error("Path is required!");
  }

  // data, error, isLoading, mutate, and other params are returned
  return useSwr(`/beta/${path}`, fetcher, {
    errorRetryCount: ERROR_RETRY_COUNT,
  });
};

const useRequestInfinite = (getKey) => {
  if (!getKey) {
    throw new Error("getKey function is required!");
  }

  // data, size, setSize, isLoading, and other params are returned
  return useSWRInfinite(getKey, fetcher);
};

export { useRequest, useRequestInfinite };
