import axiosInst from "./config/axiosConfig";

/**
 * @param {*} devicePoolToAddObj {
 devicePool      string
 orgID           string
 defaultConfigID string
 deviceCount     int
 }
 * @returns {
 devicePool      string
 orgID           string
 defaultConfigID string
 deviceCount     int
 }
 */
export async function createDevicePoolAPIHelper(devicePoolToAddObj) {
  const devicePoolObj = { ...devicePoolToAddObj };
  if (devicePoolObj.orgID === undefined || devicePoolObj.orgID === null) {
    return null;
  }
  if (devicePoolObj.devicePool === undefined || devicePoolObj.devicePool === null) {
    devicePoolObj.devicePool = "default";
  }
  devicePoolObj.deviceCount = 0;

  return axiosInst
    .post("/beta/devicePools", {
      ...devicePoolObj,
    })
    .then((response) => response);
}

export async function getDevicePools(firstIndex = 0, lastIndex = 1) {
  return axiosInst
    .get(`/beta/devicePools?firstDevicePoolIndex=${firstIndex}&lastDevicePoolIndex=${lastIndex}`)
    .then((response) => response);
}

export async function getAllDevicePools() {
  return axiosInst.get("/beta/devicePools");
}

export async function getAllDeviceInPool(poolName) {
  return axiosInst.get(`/beta/devices?devicePool=${poolName}`);
}

export async function createNewPool(poolName, orgId, defaultConfigId = "") {
  return axiosInst.post("/beta/devicePools", {
    devicePool: poolName,
    orgID: orgId,
    defaultConfigId: defaultConfigId,
  });
}

export async function updateDevicePool(body) {
  return axiosInst.put("/beta/devicePools", body);
}

export async function deleteDevicePool(poolName) {
  return axiosInst.delete(`/beta/devicePools?devicePool=${poolName}`);
}

export async function getDeviceInPool(poolId, startIndex = 0, lastIndex = 1) {
  return axiosInst.get(
    `/beta/devices?devicePool=${poolId}&firstDeviceIndex=${startIndex}&lastDeviceIndex=${lastIndex}`,
  );
}
