import axiosInst from "./config/axiosConfig";

export const getUserList = async (body) => axiosInst.post("/beta/usersList", body);

export const addRemoveOrg = async (body) => axiosInst.post("/beta/groups", body);

export const addPowerUser = async (body) => axiosInst.post("/beta/poweruser", body);

export const getPowerUserList = async (body) => axiosInst.post("/beta/powerusersList", body);

export const getSupportUserInfo = async (body) => axiosInst.post("/beta/supportUser", body);

export const getOrganizationList = async (body) => axiosInst.post("/beta/poweruserGroups", body);
