import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import { Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";

const Select = ({ name, control, rules, options, width, ...props }) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    defaultValue={options?.[0]?.value}
    render={({ field }) => (
      <FormControl sx={{ width, marginTop: "16px" }} size="small">
        <TextField
          sx={{ paddingBottom: "32px" }}
          select
          {...field}
          {...props}
          inputProps={{ "aria-label": "Without label" }}
        >
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    )}
  />
);
Select.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired, // // Control<FieldValues, any>
  // eslint-disable-next-line react/forbid-prop-types
  rules: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.exact({ value: PropTypes.string.isRequired, label: PropTypes.string })),
  width: PropTypes.string,
};
Select.defaultProps = {
  width: "100%",
  rules: {},
  options: [],
};

export default Select;
