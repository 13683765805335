import { getDateString, formatPhoneNumber } from "utils/util-functions";
import { COLUMN_TYPE, USER_TYPE } from "utils/constants";

const USER_COLUMNS = [
  {
    id: "name",
    label: "Name",
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "email",
    label: "Email",
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "phone_number",
    label: "Mobile Phone Number",
    format: (value) => formatPhoneNumber(value || ""),
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "profile",
    label: "Organization",
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "roleName",
    label: "Role",
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.OPTION,
    options: [
      { value: USER_TYPE.ADMIN, label: "Admin" },
      { value: USER_TYPE.WEB_USER, label: "Web User" },
    ],
  },
  {
    id: "UserCreateDate",
    label: "Created",
    format: (value) => getDateString(value || ""),
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.DATE,
  },
  {
    id: "UserLastModifiedDate",
    label: "Updated",
    format: (value) => getDateString(value || ""),
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.DATE,
  },
  { id: "action-col", label: "" },
];

const ROLE_OPTIONS = [
  { value: USER_TYPE.WEB_USER, label: "Web User" },
  { value: USER_TYPE.ADMIN, label: "Admin" },
];

export { USER_COLUMNS, ROLE_OPTIONS };
