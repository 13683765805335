export const ACTION_JSON = "actionJson";

const PENDING_GRID_COLUMNS = [
  { id: "eiSerialNumber", label: "Serial No.", minWidth: 70, maxWidth: 70 },
  {
    id: "completed",
    label: "Completed",
    minWidth: 100,
    maxWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "attempts",
    label: "Attempts",
    align: "center",
    minWidth: 70,
    maxWidth: 70,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "maxAttempts",
    label: "Max Attempts",
    minWidth: 100,
    maxWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  { id: ACTION_JSON, label: "Pending Action", minWidth: 200, maxWidth: 200, align: "left" },
];

export default PENDING_GRID_COLUMNS;
