import styled from "@emotion/styled";
import TableContainer from "@mui/material/TableContainer";

import BREAKPOINTS from "utils/breakpoints";

export const TableWrapper = styled(TableContainer)`
  .hide-mobile {
    ${BREAKPOINTS.sm} {
      display: none;
    }
  }
`;

export const GreenStatusDot = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.success.main};
`;

export const StatusDotWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;
