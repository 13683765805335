import { COLUMN_TYPE } from "utils/constants";
import { getDateString } from "utils/util-functions";

const ORG_COLUMNS = [
  {
    id: "GroupName",
    label: "Organization Name",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "CreationDate",
    label: "Created",
    filter: true,
    isSort: true,
    format: (value) => getDateString(value || ""),
    type: COLUMN_TYPE.DATE,
  },
  {
    id: "LastModifiedDate",
    label: "Last Modified",
    filter: true,
    isSort: true,
    format: (value) => getDateString(value || ""),
    type: COLUMN_TYPE.DATE,
  },
  { id: "action-col", label: "" },
];

export default ORG_COLUMNS;
