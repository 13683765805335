import { PrimaryButton } from "components/common/Buttons";
import React from "react";
import PropTypes from "prop-types";

export default function UserPermissions({ row, isPermissionAllowed, handleGrantSupport }) {
  if (!row.Enabled && !isPermissionAllowed) {
    return <PrimaryButton onClick={() => handleGrantSupport(row, true)}>ALLOW</PrimaryButton>;
  }
  return <PrimaryButton onClick={() => handleGrantSupport(row, false)}>REVOKE</PrimaryButton>;
}

UserPermissions.propTypes = {
  row: PropTypes.string.isRequired,
  isPermissionAllowed: PropTypes.string.isRequired,
  handleGrantSupport: PropTypes.func.isRequired,
};
