import React, { useState, useMemo, useEffect } from "react";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { PageTitle } from "components/common/Text";
import { PrimaryButton } from "components/common/Buttons";
import CustomTable from "components/table";
import { TableBodyCell } from "components/table/table.style";
import { ActionBar } from "components/common/Wrapper";
import { getCurrentPage, getFilteredListNew, getSortList } from "utils/util-functions";
import { getSupportUserInfo } from "api/PowerUserHelpers";
import { useAuth } from "hooks/useProvideAuth";
import TableFilterNew from "components/table/TableFilterNew.component";
import { SUPPORT_USERNAME } from "utils/constants";
import useOrganizations from "hooks/useOrganizations";
import { ORG_COLUMNS, SUPPORT_USER_COLUMNS } from "./Support.config";

const OrganizationsPage = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [filterColumn, setFilterColumn] = useState();
  const [filterWord, setFilterWord] = useState();
  const [filterType, setFilterType] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [sortBy, setSortBy] = useState();
  const [supportList, setSupportList] = useState([]);

  const { sub: userId } = useAuth();

  const [reloadOrg, setReloadOrg] = useState(true);
  const { isLoading, orgList } = useOrganizations(userId, reloadOrg);

  useEffect(() => {
    if (!isLoading) {
      setReloadOrg(false);
    }
  }, [isLoading]);

  const supportUserColumns = useMemo(() => {
    const tempCols = [...SUPPORT_USER_COLUMNS];
    tempCols.pop();
    return tempCols;
  }, []);

  const updateSupportList = (data) => {
    const list = data.map((x) => {
      const attributes = {};
      x.Attributes.forEach((attr) => {
        attributes[attr.Name] = attr.Value;
      });
      return {
        ...x,
        ...attributes,
        status: attributes.profile === "None" ? "" : x.Enabled ? "Granted" : "Pending",
      };
    });
    setSupportList(list);
  };

  const fetchSupportUser = async () => {
    const { data } = await getSupportUserInfo({
      username: userId,
      supportname: SUPPORT_USERNAME,
    });
    updateSupportList(data);
  };

  const handleRequestAccess = async (event, row) => {
    // always one support list
    const { data } = await getSupportUserInfo({
      username: userId,
      supportname: SUPPORT_USERNAME,
      newgroupname: row.GroupName,
      oldgroupname: supportList[0].profile,
      email: supportList[0].email,
    });
    updateSupportList(data);
  };

  const handleReset = async () => {
    const { data } = await getSupportUserInfo({
      username: userId,
      supportname: SUPPORT_USERNAME,
      action: "reset",
      groupname: supportList[0].profile,
      email: supportList[0].email,
    });
    updateSupportList(data);
  };

  useEffect(() => {
    fetchSupportUser();
  }, []);

  const filteredList = useMemo(
    () => getFilteredListNew(orgList, filterColumn, filterType, filterWord),
    [orgList, filterColumn, filterWord, filterType],
  );
  const sortedList = useMemo(() => {
    const sortColumn = ORG_COLUMNS.find(({ id }) => id === sortBy);
    return getSortList(filteredList, sortBy, sortOrder, sortColumn?.type);
  }, [sortBy, sortOrder, filteredList]);
  const paginatedList = useMemo(() => getCurrentPage(sortedList, page, rowsPerPage), [sortedList, page, rowsPerPage]);
  const totalOrgCount = useMemo(() => filteredList?.length || 0, [filteredList]);

  const filterColumns = ORG_COLUMNS.filter(({ filter }) => filter);

  const orgColumns = useMemo(() => {
    const tempCols = [...ORG_COLUMNS];
    tempCols.pop();
    return tempCols;
  }, []);

  const onSearch = (column, columnType, searchContent) => {
    setFilterColumn(column);
    setFilterWord(searchContent);
    setFilterType(columnType);
    setPage(0);
  };

  const onResetFilter = () => {
    setFilterColumn();
    setFilterWord();
    setFilterType();
    setPage(0);
  };

  const handleRequestSort = (order, orderBy) => {
    setSortOrder(order);
    setSortBy(orderBy);
  };

  return (
    <>
      <PageTitle>Support access to organization</PageTitle>
      <CustomTable>
        <CustomTable.Header columns={SUPPORT_USER_COLUMNS} onRequestSort={handleRequestSort} />

        <CustomTable.Body isLoading={!supportList.length} deviceData={supportList}>
          {supportList.map(
            (row) =>
              row && (
                <TableRow key={row.Username}>
                  {supportUserColumns.map((column) => {
                    const value = row[column.id];

                    return (
                      <TableBodyCell key={column.id}>{column.format ? column.format(value) : value}</TableBodyCell>
                    );
                  })}

                  <TableBodyCell style={{ width: 120 }}>
                    {supportList[0]?.profile !== "None" && (
                      <PrimaryButton color="error" onClick={handleReset}>
                        Reset
                      </PrimaryButton>
                    )}
                  </TableBodyCell>
                </TableRow>
              ),
          )}
        </CustomTable.Body>
      </CustomTable>

      <Box sx={{ height: 50 }} />
      <PageTitle>Organizations</PageTitle>

      <ActionBar style={{ justifyContent: "space-between", alignItems: "flex-start" }}>
        {orgList?.length > 5 ? (
          <TableFilterNew columns={filterColumns} onSearch={onSearch} onReset={onResetFilter} />
        ) : (
          <div />
        )}
      </ActionBar>

      <CustomTable
        rowsPerPageOptions={[10, 25, 100]}
        totalCount={totalOrgCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(pageNum) => setPage(pageNum)}
        onRowsPerPageChange={(rowPerPage) => setRowsPerPage(rowPerPage)}
      >
        <CustomTable.Header columns={ORG_COLUMNS} onRequestSort={handleRequestSort} />

        <CustomTable.Body isLoading={isLoading} deviceData={paginatedList}>
          {paginatedList &&
            paginatedList.map(
              (row) =>
                row && (
                  <TableRow key={row.GroupName}>
                    {orgColumns.map((column) => (
                      <TableBodyCell key={column.id}>
                        {column.format ? column.format(row[column.id]) : row[column.id]}
                      </TableBodyCell>
                    ))}
                    <TableBodyCell>
                      <PrimaryButton
                        onClick={(e) => handleRequestAccess(e, row)}
                        variant="contained"
                        disabled={!supportList.length}
                      >
                        Request
                      </PrimaryButton>
                    </TableBodyCell>
                  </TableRow>
                ),
            )}
        </CustomTable.Body>
      </CustomTable>
    </>
  );
};

export default OrganizationsPage;
