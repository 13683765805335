import { useRequest } from "hooks/useRequest";
import React from "react";
import PropTypes from "prop-types";
import { compareSoftwareVersions, isMegaSoftware } from "utils/general";
import { PrimaryButton } from "components/common/Buttons";

export default function MissionControlButton({ deviceDetail, onClick }) {
  const { data: megaFiVerNum } = useRequest("minFwVerForRM");

  if (!megaFiVerNum || megaFiVerNum === "") {
    return <div />;
  }

  if (!deviceDetail.online) {
    return <div />;
  }

  if (
    !isMegaSoftware(deviceDetail.eiSoftwareVersion) ||
    !(compareSoftwareVersions(deviceDetail.eiSoftwareVersion, megaFiVerNum) >= 0)
  ) {
    return <div />;
  }

  return (
    <PrimaryButton variant="contained" onClick={onClick}>
      LAUNCH MISSION CONTROL
    </PrimaryButton>
  );
}

MissionControlButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  deviceDetail: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
