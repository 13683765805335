import styled from "@emotion/styled";

export const PageTitle = styled.div`
  font-size: 36px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: var(--font-light);
  margin-bottom: 20px;
`;

export const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: ${({ theme }) => theme.palette.text.primary};
`;
