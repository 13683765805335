import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { AuthButton } from "components/common/Buttons";
import LogoBlue from "assets/svg/nextivity_logo_color.svg";
import { showError, showInfo } from "utils/notifications";
import { verifyUser, resendVerifyCode } from "../Cogcognito";
import { VerifyForm, FormInput } from "./VerifyEmail.style";
import { PageContainer, FormSubtitle, FormTitle, LogoImg } from "../Login/Login.style";

const VerifyEmail = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const requestNewCode = (email) => {
    resendVerifyCode(email, (err) => {
      if (err) {
        console.log(err);
      }
    });
  };
  const handleVSubmit = (inputObj) => {
    const email = localStorage.getItem("destinationForVerify");
    // handleVerifySubmit(email, e)
    verifyUser(email, inputObj.verificationCode, (err, result) => {
      if (err) {
        if (err.name === "ExpiredCodeException") {
          // TODO notify user code is expired and they need to request a new one
          requestNewCode(email);
          showError(
            "Verification failed, please try again. If your code has expired, please check your email for a new code",
          );
        }
        console.log(err);
        return;
      }
      showInfo(`${result}: Verification complete! Please sign in again`);
      navigate("/login");
    });
    showInfo(`code: ${JSON.stringify(inputObj.verificationCode)} email: ${email}`);
  };

  return (
    <PageContainer>
      <VerifyForm>
        <LogoImg src={LogoBlue} alt="logo" />
        <FormTitle>Verify Email Address</FormTitle>
        <FormSubtitle>Please check your email, and enter your verification code below.</FormSubtitle>
        <FormInput
          placeholder="Enter Verification Code"
          className="form-group-label"
          {...register("verificationCode", { required: true })}
        />
        <AuthButton onClick={handleSubmit(handleVSubmit)}>Submit</AuthButton>
      </VerifyForm>
    </PageContainer>
  );
};

export default VerifyEmail;
