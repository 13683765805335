import { DataGrid } from "@mui/x-data-grid";
import styled from "@emotion/styled";

const CustomDataGrid = styled(DataGrid)`
  height: 420px;

  .MuiDataGrid-columnHeaderTitle {
    color: ${({ theme }) => theme.palette.gray[400]};
  }

  .MuiDataGrid-cellContent {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

export default CustomDataGrid;
