import { COLUMN_TYPE } from "utils/constants";
import { formatPhoneNumber, getDateString } from "utils/util-functions";

const POWER_USER_COLUMNS = [
  {
    id: "name",
    label: "Name",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "organizationName",
    label: "Organization Name",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "roleName",
    label: "Role",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "phone_number",
    label: "Phone Number",
    format: (value) => formatPhoneNumber(value || ""),
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "email",
    label: "Email",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "UserStatus",
    label: "User Status",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "UserCreateDate",
    label: "Created",
    format: (value) => getDateString(value || ""),
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.DATE,
  },
  {
    id: "UserLastModifiedDate",
    label: "Last Modified",
    format: (value) => getDateString(value || ""),
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.DATE,
  },
  { id: "action-col", label: "" },
];

export default POWER_USER_COLUMNS;
