export const LIGHT_PALETTE = {
  primary: {
    light: "#96CEEC", // Brand Color : Light Blue
    main: "#4FAEE0", // CEL-FI Blue
    dark: "#479CC9",
    contrastText: "#fff",
  },
  secondary: {
    light: "#b7b7b8",
    main: "#afb0b1",
    dark: "#9d9e9f",
    contrastText: "#fff",
  },
  error: {
    light: "#df624e",
    main: "#D73C23", // Brand color : Error
    dark: "#ac301c",
    contrastText: "#fff",
  },
  success: {
    light: "#b3cd85",
    main: "#A0C167", // Brand Color : Success
    dark: "#809a52",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  warning: {
    light: "#eca06d",
    main: "#E88949", // Brand Color : Warning
    dark: "#b96d3a",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  text: {
    primary: "#434A54", // Brand Color : Text
    secondary: "rgba(67, 74, 84, 0.87)",
    disabled: "rgba(0, 0, 0, 0.38)",
    hint: "rgba(0, 0, 0, 0.38)",
  },
  common: {
    black: "#000",
    white: "#fff",
  },
  gray: {
    // Brand Gray Colors
    500: "#656D78",
    400: "#AAB2BD",
    300: "#CCD1D9",
    200: "#E6E9ED",
    100: "#F5F5FA",
  },
  border: "rgba(0, 0, 0, 0.25)",
};

// Dark theme is on hold for now !

export const DARK_PALETTE = {
  // primary: {
  //   main: "#4FAEE0",
  //   dark: "#002C54",
  // },
  secondary: {
    main: "#ccd1d9",
    light: "#d1d5dc",
    dark: "#b7bcc3",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  error: {
    main: "#ee8980",
    light: "#ef948c",
    dark: "#d67b73",
    contrastText: "#fff",
  },
  gray: {
    100: "#71727b",
    300: "#545454",
    400: "#fff",
    500: "#8c9197",
    600: "#808080",
    700: "#8e9298",
    750: "#1c1d1e",
    800: "#aab2bd",
    900: "#1a1a1a",
    910: "#505964",
    920: "#ccd1d9",
  },
  green: {
    100: "#65c18d",
  },
  red: {
    100: "#ee8980",
  },
  blue: {
    100: "#4faee0",
  },
  bg: "#edf4ff",
  white: "#131313",
  black: "#fff",
  border: "rgba(255, 255, 255, 0.23)",
  title: "#fff",
};
