export function compareSoftwareVersions(version1: string, version2: string): number {
  const regex = /(\d+)\.(\d+)\.(\d+)/;
  const [, major1, minor1, patch1] = version1.match(regex) || [];
  const [, major2, minor2, patch2] = version2.match(regex) || [];

  const compareComponent = (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10);

  const majorComparison = compareComponent(major1, major2);
  if (majorComparison !== 0) {
    return majorComparison;
  }

  const minorComparison = compareComponent(minor1, minor2);
  if (minorComparison !== 0) {
    return minorComparison;
  }

  return compareComponent(patch1, patch2);
}

export function isMegaSoftware(version: string): boolean {
  return version.toLowerCase().startsWith("mega");
}
