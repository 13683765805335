import React from "react";
import { getDateString } from "utils/util-functions";
import { COLUMN_TYPE } from "utils/constants";

import { GreenStatusDot, StatusDotWrapper } from "./Devices.style";

const DASHBOARD_COLUMNS = [
  { id: "", label: "", width: 30 },
  {
    id: "online",
    label: "Online",
    format: (value) => <StatusDotWrapper>{value && <GreenStatusDot />}</StatusDotWrapper>,
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.OPTION,
    options: [
      { value: true, label: "Online" },
      { value: false, label: "Offline" },
    ],
  },
  { id: "eiSerialNumber", label: "Serial No", width: 80, filter: true, isSort: true, type: COLUMN_TYPE.STRING },
  { id: "eiSoftwareVersion", label: "Version", width: 80, filter: true, isSort: true, type: COLUMN_TYPE.STRING },
  {
    id: "imei",
    label: "IMEI",
    width: 110,
    align: "center",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "userName",
    label: "User Name",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "phoneNumber",
    label: "SIM Phone Number",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  { id: "deviceAlias", label: "Alias", width: 80, isSort: true, filter: true, type: COLUMN_TYPE.STRING },
  {
    id: "locationTrailEnabled",
    label: "Location Tracking",
    format: (value) => (value ? "Enabled" : "Disabled"),
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.OPTION,
    options: [
      { value: true, label: "Enabled" },
      { value: false, label: "Disabled" },
    ],
  },
  {
    id: "rxBytes",
    label: "RX Bytes",
    width: 80,
    className: "hide-mobile",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "txBytes",
    label: "TX Bytes",
    width: 80,
    className: "hide-mobile",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "lastCheckIn",
    label: "Last\u00a0Check\u00a0In",
    width: 180,
    align: "center",
    className: "hide-mobile",
    format: (value) => getDateString(value || ""),
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.DATE,
  },
  {
    id: "devicePool",
    label: "Device\u00a0Pool",
    width: 80,
    align: "left",
    className: "hide-mobile",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
];

export default DASHBOARD_COLUMNS;
