import styled from "@emotion/styled";

export const VerifyForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 380px;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid ${({ theme }) => theme.palette.gray[200]};
  border-radius: 5px;
  align-items: center;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 10%);
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const FormInput = styled.input`
  width: 100%;
  height: 35px;
  border: 1px solid ${({ theme }) => theme.palette.gray[200]};
  border-radius: 5px;
  margin: 15px 0;
`;
