import React from "react";
import PropTypes from "prop-types";
import { TextButton } from "../Buttons";
import style from "./nxt-icon-button.module.css";

export function NxtIconButton({ icon, onClick, isDisabled, text }) {
  return (
    <TextButton onClick={onClick} disabled={isDisabled}>
      <div className={style.iconWrapper}>{icon}</div>
      {text}
    </TextButton>
  );
}

NxtIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object.isRequired,
};

NxtIconButton.defaultProps = {
  isDisabled: false,
};
