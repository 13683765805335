/* eslint-disable react/prop-types */
import React, { createContext, useState, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useProvideAuth } from "hooks/useProvideAuth";
import { COLOR_MODE } from "utils/constants";
import { DARK_PALETTE, LIGHT_PALETTE } from "utils/theme";

const GlobalContext = createContext({ toggleColorMode: () => {}, colorMode: COLOR_MODE.LIGHT, auth: {} });

const ContextProvider = ({ children }) => {
  const [isDirectFromLogin, setIsDirectFromLogin] = useState(false);
  const [mode, setMode] = useState(COLOR_MODE.LIGHT);
  const auth = useProvideAuth();

  const contextValue = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === COLOR_MODE.LIGHT ? COLOR_MODE.DARK : COLOR_MODE.LIGHT));
      },
      colorMode: mode,
      auth,
      isDirectFromLogin,
      setIsDirectFromLogin,
    }),
    [mode, auth],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === COLOR_MODE.LIGHT ? LIGHT_PALETTE : DARK_PALETTE),
        },
      }),
    [mode],
  );

  return (
    <GlobalContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </GlobalContext.Provider>
  );
};

export { GlobalContext, ContextProvider };
