import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";

import Wrapper from "./LoadingSpinner.style";

export default function LoadingSpinner({ position }) {
  return (
    <Wrapper position={position}>
      <CircularProgress />
    </Wrapper>
  );
}

LoadingSpinner.propTypes = {
  position: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  position: "absolute",
};
