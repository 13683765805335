import React, { useState, useRef, useEffect, useMemo } from "react";
import L from "leaflet";
import Geohash from "latlon-geohash";
import { useParams } from "react-router";
import { Box, Slider, Typography, useTheme } from "@mui/material";
import "leaflet-routing-machine";
import moment from "moment";

import glowEffect from "assets/img/glow-effect.png";
import { useRequest } from "hooks/useRequest";
import { getDateString, getSignalStrengthColor, getColorMarker, getColorDot } from "utils/util-functions";
import { MAPBOX_STYLE_ID } from "utils/constants";
import { TextButton } from "components/common/Buttons";
import MapSidebar from "./components/MapSidebar";
import {
  MapPageContainer,
  MarkerWrapper,
  PageContent,
  PageTitle,
  RightWrapper,
  RouteToolsButtonsWrapper,
  RouteToolsHelpContainer,
} from "./MapPage.style";
import { CENTER_POINT_LATITUDE, CENTER_POINT_LONGITUDE, DATA_FIELDS_STR } from "./Map.config";

const MapPage = () => {
  const [activeDevice, setActiveDevice] = useState();
  const [showDrawer, setShowDrawer] = useState(false);
  const [deviceDataObj, setDeviceDataObj] = useState({});
  const [routeArray, setRouteArray] = useState([]);
  const [showRoute, setShowRoute] = useState(false);
  const [markerArray, setMarkerArray] = useState([]);
  const [displayHelpRouteText, setDisplayHelpRouteText] = useState("");
  const { palette } = useTheme();
  const [activeDeviceData, setActiveDeviceData] = useState([]);

  const { sn } = useParams();
  const mapRef = useRef();
  const groupRef = useRef();

  const [displayRouteTools, setDisplayRouteTools] = useState(false);
  const [sliderStep, setSliderStep] = useState([1, 1]);
  const [sliderTotalSteps, setSliderTotalSteps] = useState(0);

  const deviceArray = useMemo(() => {
    if (!deviceDataObj) return [];
    return Object.keys(deviceDataObj)
      .map((key) => deviceDataObj[key])
      .filter((item) => !!item);
  }, [deviceDataObj]);

  useEffect(() => {
    if (deviceArray.length === 1 && deviceArray[0].locations.length > 1) {
      setDisplayRouteTools(true);
      setSliderTotalSteps(deviceArray[0].locations.length);
      setSliderStep([1, deviceArray[0].locations.length]);
      setDisplayHelpRouteText(false);
    } else if (deviceArray.length > 1) {
      setDisplayHelpRouteText(true);
      setDisplayRouteTools(false);
    } else {
      setDisplayRouteTools(false);
    }
  }, [deviceArray, markerArray]);

  const addRouteToMap = (locations, isActive) => {
    const waypoints = locations.map(({ lat, lon }) => L.latLng(lat, lon));

    const instances = [];

    for (let i = 0; i < waypoints.length - 1; i++) {
      const instance = L.Routing.control({
        router: L.Routing.mapbox(process.env.REACT_APP_MAPBOX_ACCESS_TOKEN),
        waypoints: [waypoints[i], waypoints[i + 1]],
        lineOptions: {
          styles: isActive ? [{ color: "#EA4335", weight: 5, zIndex: 100 }] : [{ color: "#6FA1EC", weight: 3 }],
        },
        show: false,
        addWaypoints: false,
        routeWhileDragging: false,
        draggableWaypoints: false,
        fitSelectedRoutes: false,
        showAlternatives: false,
        createMarker: function () {
          return null;
        },
      });
      if (showRoute) {
        instance.addTo(mapRef?.current);
      }
      instances.push(instance);
    }
    return instances;
  };

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = L.map("map-container").setView([CENTER_POINT_LONGITUDE, CENTER_POINT_LATITUDE], 5);

      L.tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/${MAPBOX_STYLE_ID}/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
        {
          attribution: 'Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 19,
        },
      ).addTo(mapRef.current);

      // create feature group that will contains all markers
      groupRef.current = L.featureGroup().addTo(mapRef.current);
    }

    return () => {
      window.sessionStorage.removeItem("activeDevice");
    };
  }, []);

  const addRemoveRoutes = () => {
    // if route is not in deviceArray, then remove it!
    const newRoutes = routeArray.filter(({ serialNumber, routeInstance, visible }) => {
      const result = deviceArray.find(({ serialNumber: serial }) => serial === serialNumber);
      if (!result) {
        if (visible) {
          try {
            routeInstance.setWaypoints([]);
            mapRef?.current.removeControl(routeInstance);
          } catch {
            // placeholder
          }
        }
      }
      return !!result;
    });

    // if device is not in route array, then add it!
    deviceArray.forEach(({ locations, sn: serialNumber }) => {
      const result = newRoutes.find(({ serialNumber: serial }) => serial === serialNumber);
      if (!result) {
        const instances = addRouteToMap(locations, serialNumber === activeDevice);
        instances.forEach((instance) => {
          newRoutes.push({
            serialNumber,
            visible: showRoute,
            routeInstance: instance,
          });
        });
      }
    });

    setRouteArray(newRoutes);
  };

  const markerPopup = (lat, lon, time, color, rssi, rsrp) => `
    <div>
      <div>Date: ${getDateString(time)}</div>
      <div>Latitude: ${lat}</div>
      <div>Longitude: ${lon}</div>
      <div>
        RSRP: <span style="color: ${color}">${rsrp === 0 ? "" : rsrp}</span>
      </div>
    </div>
  `;

  const createCustomIcon = (index, color, serialNumber) =>
    index === 0
      ? L.icon({
          iconUrl: getColorMarker(color),
          shadowUrl: serialNumber === activeDevice ? glowEffect : null,
          shadowSize: [70, 70],
          shadowAnchor: [35, 60],
          iconSize: [15, 29],
          iconAnchor: [7, 29],
          popupAnchor: [0, -30],
        })
      : L.icon({
          iconUrl: getColorDot(color),
          shadowUrl: serialNumber === activeDevice ? glowEffect : null,
          shadowSize: [70, 70],
          shadowAnchor: [35, 35],
          iconSize: [15, 15],
          iconAnchor: [7, 7],
          popupAnchor: [0, -5],
        });

  const addRemoveMarkers = () => {
    const array = [...markerArray];

    // if marker is not in deviceArray, then remove it!
    const newRoutes = array.filter(({ serialNumber, markerInstanceArray }) => {
      const result = deviceArray.find(({ serialNumber: serial }) => serial === serialNumber);

      if (!result && markerInstanceArray.length > 0) {
        markerInstanceArray.forEach((markerInstance) => {
          if (markerInstance.options.opacity && markerInstance) {
            groupRef?.current?.removeLayer(markerInstance);
          }
        });
      }

      return !!result;
    });

    // if device is not in marker array, then add it!
    deviceArray.forEach(({ locations, sn: serialNumber }) => {
      const result = newRoutes.find(({ serialNumber: serial }) => serial === serialNumber);
      if (!result && groupRef?.current) {
        const markerInstanceArray = locations.map(({ time, rssi, rsrp, lat, lon, color }, index) => {
          const customIcon = createCustomIcon(index, color, serialNumber);
          const marker = L.marker([lat, lon], { icon: customIcon, title: index + 1 }).addTo(groupRef.current);
          const popupContent = markerPopup(lat, lon, time, color, rssi, rsrp);

          // Add click event handler on marker
          marker.on("click", () => {
            // setActiveDevice(serialNumber);
            mapRef.current?.flyTo([lat, lon], mapRef.current?.getZoom(), {
              animate: true,
              duration: 1,
            });

            marker.unbindPopup().bindPopup(popupContent).openPopup();
          });

          return marker;
        });

        newRoutes.push({
          serialNumber,
          markerInstanceArray,
        });
      }
    });

    setMarkerArray(newRoutes);
  };

  useEffect(() => {
    // if selected devices are changed, then add/remove markers & routes!
    // This should be done using JavaScript
    addRemoveRoutes();
    addRemoveMarkers();
  }, [deviceArray]);

  useEffect(() => {
    if (sn?.length > 0) {
      // setActiveDevice(sn);
    }
  }, [sn]);

  const initialChecked = useMemo(() => {
    if (sn?.length > 0) {
      return [sn];
    }
    return [];
  }, [sn]);

  const exportCsv = (deviceLocationData) => {
    const timeStamp = new Date();
    const combinedLabels = [
      "Type",
      "Serial_Number",
      "IMEI",
      "Phone_Number",
      "Device_Alias",
      "DateTime_GPS",
      "RSSI",
      "RSRP",
      "LAT",
      "LONG",
    ];
    const csvDataLabels = `${combinedLabels}\n`;
    const deviceLocationStrings = deviceLocationData.map((row) => Object.values(row).join(",")).join("\n");
    const hiddenElement = document.createElement("a");

    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvDataLabels + deviceLocationStrings)}`;
    hiddenElement.target = "_blank";
    hiddenElement.download = `MegaFi_${timeStamp}.csv`;
    hiddenElement.click();
  };

  const getMapDeviceData = () => {
    let retrievedSerial = "";
    const storedActiveDevice = window.sessionStorage.getItem("activeDevice");

    if (storedActiveDevice) {
      const parsedSerialArr = JSON.parse(storedActiveDevice);
      const parsedSerial = parsedSerialArr[0];
      retrievedSerial = parsedSerial;
    } else {
      retrievedSerial = sn;
    }

    // filter data points determined by map slider
    const firstStep = sliderStep[0] - 1;
    const lastStep = sliderStep[1];

    if (activeDeviceData) {
      const deviceData = activeDeviceData.filter((dataObj) => dataObj.mappingData[0].device.sn === retrievedSerial);
      if (deviceData) {
        const mappedData = deviceData[0].mappingData;
        mappedData.map(({ dataPoints, device }) => {
          const locationPoints = [...dataPoints].sort((a, b) => a.timeStamp - b.timeStamp);
          const deviceLocationDataPoints = locationPoints
            .map(({ geohash, rssi, rsrp, timeStamp }) => ({
              type: "Map data points",
              sn: `${device.sn}\t`,
              imei: `${device.imei}\t`,
              ph: `${device.ph}\t`,
              al: `${device.al}`,

              // Excel reformats time stamp to scientific notication. The equation below corrects this.
              DateTime_GPS: timeStamp / 86400000 + 25569,
              rssi: `${rssi}`,
              rsrp: `${rsrp}`,
              ...(geohash ? Geohash.decode(geohash) : {}),
            }))
            .slice(firstStep, lastStep);

          return exportCsv(deviceLocationDataPoints);
        });
      }
    }
  };

  const onDeviceLoaded = (serialNumber, data) => {
    const tempData = deviceDataObj;

    if (data) {
      const { mappingData } = data;

      if (mappingData && mappingData.length) {
        setActiveDeviceData([...activeDeviceData, data]);
        const deviceData = mappingData.map(({ dataPoints, device }) => {
          const { sn: oneSerialNumber } = device;
          const locationPoints = [...dataPoints].sort((a, b) => a.timeStamp - b.timeStamp);

          const locations = locationPoints
            .map(({ geohash, rssi, rsrp, timeStamp }) => ({
              timeStamp,
              time: new Date(timeStamp).toString(),
              color: getSignalStrengthColor(rsrp),
              rssi,
              rsrp,
              ...(geohash ? Geohash.decode(geohash) : {}),
            }))
            .filter((x) => x.lat && x.lon);

          return {
            locations,
            sn: oneSerialNumber,
          };
        });

        if (deviceData?.length > 0) {
          [tempData[serialNumber]] = deviceData;
        } else {
          tempData[serialNumber] = null;
        }
      } else {
        tempData[serialNumber] = null;
      }
    } else {
      tempData[serialNumber] = null;
    }

    setDeviceDataObj({ ...tempData });
  };

  const { data: deviceData } = useRequest(sn ? `mappingUIHook?sn=${sn}${DATA_FIELDS_STR}` : "mappingUIHook");

  useEffect(() => {
    const { mappingData } = deviceData || {};
    if (sn && deviceDataObj[sn] === undefined && mappingData?.length > 0) {
      onDeviceLoaded(sn, deviceData);
    }
  }, [deviceData, sn]);

  useEffect(() => {
    const bounds = groupRef?.current?.getBounds();
    if (bounds && Object.keys(bounds).length > 0) {
      mapRef?.current?.fitBounds(groupRef?.current?.getBounds());
    }
  }, [groupRef?.current, deviceArray]);

  const onSelectedDeviceChange = (devices) => {
    // check if active device is deselected

    if (activeDevice) {
      const isIncluded = devices?.find((item) => item === activeDevice);
      if (!isIncluded) {
        setActiveDevice(null);
      }
    }
  };

  const handleStepChange = (value) => {
    const startChanged = sliderStep[0] !== value[0];
    setSliderStep(value);
    if (markerArray && markerArray.length > 0) {
      markerArray[0].markerInstanceArray.forEach((marker, index) => {
        const isMarkerVisible = index >= value[0] - 1 && index <= value[1] - 1;

        if (isMarkerVisible) {
          if (!marker.options.opacity) {
            marker.setOpacity(1);
            marker.addTo(groupRef?.current);
          }
        } else {
          marker.setOpacity(0);
          groupRef?.current?.removeLayer(marker);
        }

        const isRouteVisible = index >= value[0] - 1 && index < value[1] - 1 && showRoute;
        if (routeArray[index]) {
          if (isRouteVisible) {
            if (!routeArray[index].visible) {
              routeArray[index].routeInstance.addTo(mapRef?.current);
              routeArray[index].visible = true;
            }
          } else {
            mapRef?.current.removeControl(routeArray[index].routeInstance);
            routeArray[index].visible = false;
          }
        }
      });
    }
    const changedIndex = startChanged ? value[0] - 1 : value[1] - 1;

    if (deviceArray[0].locations[changedIndex]) {
      const { lat, lon } = deviceArray[0].locations[changedIndex];
      mapRef.current?.flyTo([lat, lon], mapRef.current?.getZoom(), {
        animate: true,
        duration: 1,
      });
    }
  };

  useEffect(() => {
    if (displayRouteTools && showRoute) {
      handleStepChange(sliderStep);
    }
  }, [displayRouteTools, showRoute]);

  const toggleDeviceRoute = () => {
    if (showRoute) {
      setShowRoute(false);
      // HIDE
      routeArray.forEach((route) => {
        if (route.visible) {
          mapRef?.current.removeControl(route.routeInstance);
          // eslint-disable-next-line
          route.visible = false;
        }
      });
    } else {
      // SHOW VALID ONLY
      setShowRoute(true);
      // SHOW ALL
      if (!displayRouteTools) {
        routeArray.forEach((route) => {
          if (!route.visible) {
            route.routeInstance.addTo(mapRef?.current);
            // eslint-disable-next-line
            route.visible = true;
          }
        });
      }
    }
  };

  const valueLabelFormat = (value) => {
    try {
      if (deviceArray[0]) {
        const date = moment(deviceArray[0].locations[value - 1].timeStamp).format("MM/DD/YY h:mm A");
        return `${value} (${date})`;
      }
      return "";
    } catch (error) {
      return console.warn("error", error);
    }
  };

  return (
    <MapPageContainer>
      <PageContent>
        <div>
          <PageTitle>Map</PageTitle>
          <MapSidebar
            onDeviceChange={onSelectedDeviceChange}
            activeDevice={activeDevice}
            showDrawer={showDrawer}
            onCloseDrawer={() => setShowDrawer(false)}
            initialChecked={initialChecked}
            onDeviceLoaded={onDeviceLoaded}
            dataFieldStr={DATA_FIELDS_STR}
          />
        </div>

        <RightWrapper className={palette.mode}>
          {displayHelpRouteText && (
            <RouteToolsHelpContainer>
              <Typography variant="body2">
                Route tools can only be displayed when a single device is selected and contains mapped location points.
              </Typography>
            </RouteToolsHelpContainer>
          )}
          {displayRouteTools && (
            <>
              <RouteToolsButtonsWrapper>
                <Box display="flex" justifyContent="center" paddingRight="160px">
                  <TextButton onClick={getMapDeviceData}>Download Data</TextButton>
                </Box>
                <Box display="flex" justifyContent="center">
                  <TextButton onClick={() => toggleDeviceRoute()}>
                    {showRoute ? "Hide" : "Show"} Device Route
                  </TextButton>
                </Box>
              </RouteToolsButtonsWrapper>
              <MarkerWrapper>
                <Slider
                  getAriaLabel={() => "Route"}
                  getAriaValueText={() => ""}
                  valueLabelDisplay="auto"
                  valueLabelFormat={valueLabelFormat}
                  step={1}
                  onChange={(event, value) => {
                    handleStepChange(value);
                  }}
                  value={sliderStep}
                  marks
                  min={1}
                  max={sliderTotalSteps}
                />
              </MarkerWrapper>
            </>
          )}

          <div
            id="map-container"
            style={{
              height: "100%",
              width: "100%",
            }}
          />
          <Typography variant="body2">
            Please note the location, signal strength, and other information is as reported by the device. No specific
            claim of accuracy is made related to this information.
          </Typography>
        </RightWrapper>
      </PageContent>
    </MapPageContainer>
  );
};

export default MapPage;
