import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";

import ActionBarSelect from "components/action-bar-select/action-bar-select";
import { FormInput } from "components/common/Input";
import { PrimaryButton, TextButton } from "components/common/Buttons";
import { getDateString } from "utils/util-functions";
import { COLUMN_TYPE } from "utils/constants";
import { DateFilterWrapper, FilterWrapper } from "./table.style";
import style from "./table-filter-new.module.css";

const TableFilterNew = ({ columns, onSearch, onReset, onPrevSearch, onNextSearch, showPrevButton, showNextButton }) => {
  const [activeFilter, setActiveFilter] = useState();
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));

  const isValidFilter = useMemo(() => !!activeFilter, [activeFilter]);
  const columnOptions = useMemo(() => columns?.map(({ id, label }) => ({ value: id, label })) || [], [columns]);

  const onResetFilter = () => {
    setActiveFilter(null);
    onReset?.();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm();
  const columnField = watch("column", "");

  const selectedColumn = useMemo(() => {
    const selected = columns.find(({ id }) => id === columnField);
    return selected || columns?.[0];
  }, [columnField]);

  const onSearchClick = ({ option, searchWord: word }) => {
    const { id, type } = selectedColumn;

    if (type === COLUMN_TYPE.STRING || type === COLUMN_TYPE.NUMBER) {
      onSearch(id, type, word);
      setActiveFilter(`${selectedColumn.label} = ${word}`);
    }
    if (type === COLUMN_TYPE.OPTION) {
      onSearch(id, type, option);
      setActiveFilter(`${selectedColumn.label} = ${option}`);
    }
    if (type === COLUMN_TYPE.DATE) {
      onSearch(id, type, {
        startDate,
        endDate,
      });
      setActiveFilter(`${selectedColumn.label} = ${getDateString(startDate)} ~ ${getDateString(endDate)}`);
    }
  };

  return (
    <FilterWrapper>
      <Box className={style.filterContainer}>
        <Box className={style.selectContainer}>
          <ActionBarSelect
            name="column"
            options={columnOptions}
            control={control}
            rules={{ required: "Column field is required" }}
            error={errors.column}
          />
        </Box>

        {selectedColumn.type === COLUMN_TYPE.OPTION && (
          <Box className={style.selectOptions}>
            <ActionBarSelect
              name="option"
              options={selectedColumn.options || []}
              control={control}
              error={errors.option}
            />
          </Box>
        )}

        {selectedColumn.type === COLUMN_TYPE.STRING && (
          <Box className={style.formInputContainer}>
            <FormInput
              {...register("searchWord", { required: "Please enter text to search!" })}
              placeholder="Search by attribute"
              error={errors.searchWord}
            />
            {errors.searchWord && <span className="error-text">{errors.searchWord.message}</span>}
          </Box>
        )}

        {selectedColumn.type === COLUMN_TYPE.NUMBER && (
          <Box className={style.formInputContainer}>
            <FormInput
              {...register("searchWord", { required: "Please enter number to search!" })}
              placeholder="Search by attribute"
              error={errors.searchWord}
            />
            {errors.searchWord && <span className="error-text">{errors.searchWord.message}</span>}
          </Box>
        )}

        {selectedColumn.type === COLUMN_TYPE.DATE && (
          <DateFilterWrapper>
            <span className={style.dateTimeMargin}>From:</span>
            <DateTimePicker value={startDate} onChange={(newValue) => setStartDate(newValue)} />
            <span className={style.dateTimeMargin}>To:</span>
            <DateTimePicker value={endDate} onChange={(newValue) => setEndDate(newValue)} />
          </DateFilterWrapper>
        )}

        {isValidFilter && showPrevButton && (
          <TextButton onClick={() => onPrevSearch?.()}>Search in Prev page</TextButton>
        )}

        <div className={style.searchButtonMargin}>
          <PrimaryButton onClick={handleSubmit(onSearchClick)}>Search</PrimaryButton>
        </div>

        {isValidFilter && showNextButton && (
          <TextButton onClick={() => onNextSearch?.()}>Search in Next page</TextButton>
        )}

        {isValidFilter && (
          <div className={style.resetButtonMargin}>
            <TextButton onClick={onResetFilter}>Reset Filter</TextButton>
          </div>
        )}
      </Box>
    </FilterWrapper>
  );
};

TableFilterNew.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  onSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onPrevSearch: PropTypes.func,
  onNextSearch: PropTypes.func,
  showPrevButton: PropTypes.bool,
  showNextButton: PropTypes.bool,
  resultCount: PropTypes.number,
};
TableFilterNew.defaultProps = {
  onReset: null,
  onPrevSearch: () => {},
  onNextSearch: () => {},
  showPrevButton: false,
  showNextButton: false,
  resultCount: 0,
};

export default TableFilterNew;
