import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "leaflet/dist/leaflet.css";
import "./index.css";

const app = document.getElementById("root");

const root = ReactDOM.createRoot(app);

window.onbeforeunload = function () {
  localStorage.removeItem("data");
  localStorage.removeItem("apiFailCount");
};

root.render(<App />);
