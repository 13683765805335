import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

export const ErrorScreenWrapper = styled.div`
  display: flex;
  height: calc(100vh - 115px);
  color: ${({ theme }) => theme.palette.text.primary};
  align-items: center;
  justify-content: center;
  font-size: 20px;
  white-space: pre-wrap;
`;

const ErrorScreen = ({ text }) => <ErrorScreenWrapper>{text}</ErrorScreenWrapper>;

ErrorScreen.propTypes = {
  text: PropTypes.string,
};

ErrorScreen.defaultProps = {
  text: "Errors occurred while calling the API.",
};

export default ErrorScreen;
