import styled from "@emotion/styled";

import BREAKPOINTS from "utils/breakpoints";

export const PageContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 380px;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid ${({ theme }) => theme.palette.gray[200]};
  border-radius: 5px;
  align-items: center;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 10%);
  background-color: ${({ theme }) => theme.palette.common.white};

  ${BREAKPOINTS.sm} {
    height: 100%;
    width: 100%;
    border: none;
    justify-content: center;
  }
`;

export const FormTitle = styled.span`
  font-size: 20px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.palette.gray[500]};
`;

export const FormSubtitle = styled.span`
  font-size: 15px;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.palette.gray[500]};
`;

export const FormInput = styled.input`
  width: 100%;
  height: 35px;
  border: 1px solid ${({ theme }) => theme.palette.gray[200]};
  border-radius: 5px;
  margin: 15px 0;
  margin-bottom: 0px;
`;

export const NewMember = styled.div`
  color: ${({ theme }) => theme.palette.gray[500]};
  font-size: 15px;
  width: 100%;
  text-align: center;
  margin-top: 20px;

  span {
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
  }
`;

export const AccountInfo = styled.p`
  a {
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
    text-decoration: unset;
  }
`;

export const LogoImg = styled.img`
  margin-bottom: 30px;
  margin-top: 15px;
  width: 280px;
  height: 45px;
`;
