import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FormInput, FieldError } from "components/common/Input";
import { AuthButton } from "components/common/Buttons";
import LogoBlue from "assets/svg/nextivity_logo_color.svg";
import { emailRegex } from "utils/constants";
import { showError } from "utils/notifications";
import { forgotPassword, forgotPasswordSubmit } from "../Cogcognito";
import FORGOT_PASSWORD_STEP from "./ForgotPassword.config";
import { PageContainer, FormWrapper, FormTitle, FormSubtitle, LogoImg } from "../Login/Login.style";

const ForgotPassword = () => {
  const [formStep, setFormStep] = useState(FORGOT_PASSWORD_STEP.RESET_PASSWORD);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({});
  const newPassword = watch("newPassword", "");
  const navigate = useNavigate();

  const onFormSubmit = (data) => {
    const { resetEmail, verifyCode, newPassword: password } = data;
    console.log("data:", data);
    if (formStep === FORGOT_PASSWORD_STEP.RESET_PASSWORD) {
      if (resetEmail) {
        forgotPassword(resetEmail, (err) => {
          if (!err) {
            setFormStep(FORGOT_PASSWORD_STEP.CONFIRM_CODE);
          } else {
            showError(err.message);
          }
        });
      }
    } else if (formStep === FORGOT_PASSWORD_STEP.CONFIRM_CODE) {
      forgotPasswordSubmit(resetEmail, verifyCode, password, (err) => {
        if (!err) {
          setFormStep(FORGOT_PASSWORD_STEP.SUCCESS);
        } else {
          showError(err.message);
        }
      });
    } else {
      navigate("/login");
    }
  };

  return (
    <PageContainer>
      <FormWrapper>
        <LogoImg src={LogoBlue} alt="logo" />
        {formStep === FORGOT_PASSWORD_STEP.RESET_PASSWORD && (
          <>
            <FormTitle>Password Reset</FormTitle>
            <FormSubtitle>
              Please enter the email address associated with your account to reset your password.
            </FormSubtitle>
            <FormInput
              placeholder="Email Address"
              {...register("resetEmail", {
                required: "Email field is required.",
                pattern: {
                  value: emailRegex,
                  message: "Entered value does not match email format.",
                },
              })}
              style={{ marginTop: 20 }}
              error={errors.resetEmail}
            />
            {errors.resetEmail && <FieldError>{errors.resetEmail.message}</FieldError>}

            <AuthButton onClick={handleSubmit(onFormSubmit)} sx={{ mt: 2 }}>
              Send Email
            </AuthButton>
          </>
        )}

        {formStep === FORGOT_PASSWORD_STEP.CONFIRM_CODE && (
          <>
            <FormTitle>Confirm Verification Code</FormTitle>
            <FormSubtitle>Please check your email and enter verification code.</FormSubtitle>

            <FormInput
              placeholder="Verification Code"
              {...register("verifyCode", {
                required: "Verification Code is required.",
              })}
              style={{ marginTop: 20 }}
              error={errors.verifyCode}
            />
            {errors.verifyCode && <FieldError>{errors.verifyCode.message}</FieldError>}

            <FormInput
              type="password"
              placeholder="New Password"
              {...register("newPassword", {
                required: "New password is required.",
              })}
              style={{ marginTop: 20 }}
              error={errors.newPassword}
            />
            {errors.newPassword && <FieldError>{errors.newPassword.message}</FieldError>}

            <FormInput
              type="password"
              placeholder="Confirm Password"
              {...register("confirmPassword", {
                required: "Confirm password is required.",
                validate: (value) => value === newPassword || "Passwords do not match",
              })}
              style={{ marginTop: 20 }}
              error={errors.confirmPassword}
            />
            {errors.confirmPassword && <FieldError>{errors.confirmPassword.message}</FieldError>}

            <AuthButton onClick={handleSubmit(onFormSubmit)} sx={{ mt: 2 }}>
              Change Password
            </AuthButton>
          </>
        )}

        {formStep === FORGOT_PASSWORD_STEP.SUCCESS && (
          <>
            <FormTitle>Success</FormTitle>
            <FormSubtitle>Your password is changed now!</FormSubtitle>
            <AuthButton onClick={handleSubmit(onFormSubmit)} sx={{ mt: 2 }}>
              Back to Login
            </AuthButton>
          </>
        )}
      </FormWrapper>
    </PageContainer>
  );
};

export default ForgotPassword;
