import React, { useState } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import LoadingSpinner from "components/LoadingSpinner";
import { SORT_DIRECTION } from "utils/constants";
import { TableWrapper, NoDataWrapper, TableHeaderCell } from "./table.style";

const CustomTable = ({
  children,
  rowsPerPageOptions,
  totalCount,
  onPageChange,
  page,
  onRowsPerPageChange,
  rowsPerPage,
  ...rest
}) => {
  const handleChangePage = (event, newPage) => {
    onPageChange?.(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    onRowsPerPageChange?.(+event.target.value);
    onPageChange?.(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", position: "relative" }}>
      <TableWrapper>
        <Table stickyHeader aria-label="sticky table" {...rest}>
          {children}
        </Table>
      </TableWrapper>

      {rowsPerPageOptions?.length > 0 && rowsPerPage > 0 && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};
CustomTable.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  rowsPerPage: PropTypes.number,
  totalCount: PropTypes.number,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
};
CustomTable.defaultProps = {
  totalCount: 0,
  page: 0,
  onPageChange: undefined,
  onRowsPerPageChange: undefined,
  rowsPerPageOptions: [],
  rowsPerPage: 0,
};

const CustomTableHeader = ({ columns, onRequestSort, ...rest }) => {
  const [order, setOrder] = useState(SORT_DIRECTION.ASC);
  const [orderBy, setOrderBy] = useState();

  const createSortHandler = (property) => () => {
    // update internal state
    const isAsc = orderBy === property && order === SORT_DIRECTION.ASC;
    setOrder(isAsc ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC);
    setOrderBy(property);

    // sent event to outside
    onRequestSort?.(isAsc ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC, property);
  };

  return (
    <TableHead {...rest}>
      <TableRow>
        {columns.map(({ id, className, align, width, label, isSort }) => (
          <TableHeaderCell className={className} key={id} align={align} style={{ width: width }}>
            {id && label && isSort ? (
              <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? order : SORT_DIRECTION.ASC}
                onClick={createSortHandler(id)}
              >
                {label}
              </TableSortLabel>
            ) : (
              <div>{label}</div>
            )}
          </TableHeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
CustomTableHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func,
};
CustomTableHeader.defaultProps = {
  onRequestSort: undefined,
};

const CustomTableBody = ({ children, isLoading, deviceData, ...rest }) => (
  <TableBody {...rest}>
    {children}
    {!isLoading && !deviceData?.length && (
      <TableRow>
        <NoDataWrapper colSpan={12} style={{ height: 150 }}>
          No records found!
        </NoDataWrapper>
      </TableRow>
    )}
    {isLoading && (
      <TableRow>
        <NoDataWrapper colSpan={12} style={{ height: 150, position: "relative" }}>
          <LoadingSpinner />
        </NoDataWrapper>
      </TableRow>
    )}
  </TableBody>
);
CustomTableBody.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  deviceData: PropTypes.array,
};
CustomTableBody.defaultProps = {
  children: null,
  deviceData: null,
};

CustomTable.Header = CustomTableHeader;

CustomTable.Body = CustomTableBody;

export default CustomTable;
