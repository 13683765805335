import { COLUMN_TYPE } from "utils/constants";

const POOLS_COLUMNS = [
  {
    id: "devicePool",
    label: "Pool Name",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "userName",
    label: "User Name",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "deviceCount",
    label: "Device Count",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.NUMBER,
  },
  { id: "action-col", label: "" },
];

export default POOLS_COLUMNS;
