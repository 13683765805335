import React, { useEffect, useState, useMemo } from "react";
import PersonAddOutlined from "@mui/icons-material/PersonAddAlt1Outlined";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { useForm, useFormState } from "react-hook-form";
import { isPossiblePhoneNumber } from "react-phone-number-input";

import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material";

import { PageTitle } from "components/common/Text";
import { useAuth } from "hooks/useProvideAuth";
import { getUserGroup, updateUserSetting } from "api/UserSettingHelpers";
import { createNewUser, deleteUser, getUserListByGroup } from "api/AdminHelpers";
import { GhostButton, PrimaryButton } from "components/common/Buttons";
import { TableBodyCell } from "components/table/table.style";
import CustomTable from "components/table";
import { FieldError, PhoneInputWrapper, StyledTextField } from "components/common/Input";
import { Popup } from "components/common/popup/popup.component";
import {
  convertCognitoAttr,
  getCurrentPage,
  getFilteredListNew,
  getSortList,
  nameValidationCheck,
} from "utils/util-functions";
import { PageFooter } from "components/common/Wrapper";
import { Spinner } from "components/common/spinner/spinner.component";
import { NxtIconButton } from "components/common/nxt-icon-button/nxt-icon-button.component";
import Select from "components/common/Select";
import TableFilterNew from "components/table/TableFilterNew.component";
import { SUPPORT_USERNAME, emailRegex } from "utils/constants";
import { showSuccess } from "utils/notifications";
import { getSupportUserInfo } from "api/PowerUserHelpers";
import useConfirm from "hooks/useConfirm";
import UserPermissions from "components/table/user-permissions/user-permissions.component";
import CustomPhoneNumber from "../../components/common/phone-input/phone-input.component";
import { USER_COLUMNS, ROLE_OPTIONS } from "./Users.config";
import style from "./users.page.module.css";

const Users = () => {
  const { sub: userId } = useAuth();
  const [userList, setUserList] = useState([]);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [isOpenNewUserModal, setIsOpenNewUserModal] = useState(false);
  const [isOpenUpdateUserModal, setIsOpenUpdateUserModal] = useState(false);
  const [isCreatingNewUser, setIsCreatingNewUser] = useState(false);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [phoneNum, setPhoneNum] = useState();
  const [currGroupName, setCurrGroupName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [filterColumn, setFilterColumn] = useState();
  const [filterType, setFilterType] = useState();
  const [filterWord, setFilterWord] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [sortBy, setSortBy] = useState();
  const [isPermissionAllowed, setIsPermissionAllowed] = useState(false);
  const [currentRowRole, setCurrentRowRole] = useState("");
  const open = Boolean(anchorEl);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [modalMessage, setModalMessage] = useState("Creating new user...");

  const theme = useTheme();
  const [getConfirmation, Confirmation] = useConfirm();

  const filterColumns = useMemo(() => USER_COLUMNS.filter(({ filter }) => filter), [USER_COLUMNS]);

  const filteredList = useMemo(
    () => getFilteredListNew(userList, filterColumn, filterType, filterWord),
    [userList, filterColumn, filterWord, filterType],
  );

  const sortedList = useMemo(() => {
    const sortColumn = USER_COLUMNS.find(({ id }) => id === sortBy);
    return getSortList(filteredList, sortBy, sortOrder, sortColumn?.type);
  }, [sortBy, sortOrder, filteredList]);

  const paginatedList = useMemo(() => getCurrentPage(sortedList, page, rowsPerPage), [sortedList, page, rowsPerPage]);

  const totalUserCount = useMemo(() => filteredList?.length || 0, [filteredList]);

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const formState = useFormState({ control });

  const {
    handleSubmit: handleSubmitUpdate,
    formState: { errors: errorsUpdate },
    control: controlUpdate,
    setValue,
  } = useForm();
  const formStateUpdate = useFormState({ control: controlUpdate });

  const userColumns = useMemo(() => {
    const columns = [...USER_COLUMNS];
    columns.pop();
    return columns;
  }, []);

  const fetchUserGroup = async () => {
    try {
      setIsLoadingList(true);

      const { data: userGroup } = await getUserGroup({ username: userId });

      if (userGroup?.length > 0) {
        const { GroupName: groupName } = userGroup[0];
        setCurrGroupName(groupName);

        const fetchUserListByGroup = async (token) => {
          try {
            const { data } = await getUserListByGroup({ username: userId, groupname: groupName, token });
            if (token) {
              setUserList((value) => [
                ...value,
                ...(data.Users?.map(({ Attributes, ...rest }) => {
                  const userData = convertCognitoAttr(Attributes);
                  return {
                    ...userData,
                    ...rest,
                  };
                }) || []),
              ]);
            } else {
              setUserList(
                data.Users?.map(({ Attributes, ...rest }) => {
                  const userData = convertCognitoAttr(Attributes);
                  return {
                    ...userData,
                    ...rest,
                  };
                }),
              );
            }

            if (data.NextToken) {
              await fetchUserListByGroup(data.NextToken);
            }
          } catch (error) {
            // error handling
          } finally {
            // setIsLoading(false);
          }
        };

        fetchUserListByGroup();
      }
    } catch (error) {
      // Error handling
    } finally {
      setIsLoadingList(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserGroup();
    }
  }, [userId]);

  useEffect(() => {
    // Reset the text field values for new user input
    const defaultValues = {};
    defaultValues.userName = "";
    defaultValues.email = "";
    reset({ ...defaultValues });
  }, [isOpenNewUserModal]);

  const onModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setIsOpenNewUserModal(false);
    }
  };

  const onUserModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setIsOpenUpdateUserModal(false);
    }
  };

  const onAddNewUser = async ({ userName, role, email }) => {
    onModalClose();

    setIsLoadingUser(true);
    setModalMessage("Creating User...");

    if (!currGroupName) return;
    if (!phoneNum || !isPossiblePhoneNumber(phoneNum)) return;

    setIsCreatingNewUser(true);
    try {
      await createNewUser({
        groupname: currGroupName,
        name: userName,
        email,
        phonenumber: phoneNum,
        role,
        username: userId,
      });
      setIsLoadingUser(false);
      showSuccess("New User created successfully!");
      fetchUserGroup();
    } catch (error) {
      console.warn(error);
      setIsLoadingUser(false);
    } finally {
      setIsCreatingNewUser(false);
    }
  };

  const onNewUser = () => {
    setIsOpenNewUserModal(true);
    setPhoneNum("");
  };

  const onClickMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const onDeleteUser = async () => {
    setAnchorEl(null);

    setIsLoadingUser(true);
    setModalMessage("Deleting user...");

    const isConfirm = await getConfirmation({
      title: "Delete User",
      content:
        "If this user has devices in his pool, transfer these devices to your pool before deleting this user. Proceed to delete?",
    });

    if (isConfirm) {
      const { email } = currentRow;

      try {
        await deleteUser({ userEmail: email, username: userId });
        showSuccess("Deleted user successfully!");

        if (userId) {
          fetchUserGroup();
        }
        setIsLoadingUser(false);
      } catch (error) {
        // error handling
      }
    }
  };

  const onUpdateUser = async ({ role }) => {
    if (!phoneNum || !isPossiblePhoneNumber(phoneNum)) return;

    onUserModalClose();

    setIsUpdatingUser(true);

    setIsLoadingUser(true);
    setModalMessage("Updating User...");

    try {
      await updateUserSetting({
        username: userId,
        email: currentRow.email,
        phonenumber: phoneNum,
        role,
      });

      setIsLoadingUser(false);
      showSuccess("Updated user successfully!");
      fetchUserGroup();
      setIsOpenUpdateUserModal(false);
    } catch (error) {
      // error handling
    } finally {
      setIsUpdatingUser(false);
    }
  };

  const showUpdateModal = () => {
    setAnchorEl(null);

    const { phone_number: phoneNumber, roleName } = currentRow;
    setPhoneNum(phoneNumber);
    setCurrentRowRole(roleName);
    setValue("role", roleName);
    setIsOpenUpdateUserModal(true);
  };

  const onSearch = (column, columnType, searchContent) => {
    setFilterColumn(column);
    setFilterWord(searchContent);
    setFilterType(columnType);
    setPage(0);
  };

  const onResetFilter = () => {
    setFilterColumn();
    setFilterWord();
    setFilterType();
    setPage(0);
  };

  const handleRequestSort = (order, orderBy) => {
    setSortOrder(order);
    setSortBy(orderBy);
  };

  const handleGrantSupport = async (row, allow = true) => {
    handleClose();
    await getSupportUserInfo({
      username: userId,
      supportname: SUPPORT_USERNAME,
      allow: allow.toString(),
      email: row.email,
    });
    setIsPermissionAllowed(allow);

    showSuccess(
      allow
        ? `Successfully allowed access to ${SUPPORT_USERNAME}`
        : `Successfully revoked access from ${SUPPORT_USERNAME}`,
    );
  };

  const borderColor = {
    borderTop: "2px solid #4FAEE0",
    borderBottom: "2px solid #4FAEE0",
  };

  const rightBorderColor = {
    border: "2px solid #4FAEE0",
    borderLeft: "none",
  };

  const leftBorderColor = {
    border: "2px solid #4FAEE0",
    borderRight: "none",
  };

  const styleRow = (name, columnId) => {
    if (name === SUPPORT_USERNAME) {
      if (columnId === 0) {
        return leftBorderColor;
      }

      return borderColor;
    }
    return {};
  };

  return (
    <>
      <PageTitle>Users</PageTitle>
      <div className={style.barContainer}>
        <div className={style.filterContainer}>
          {userList?.length > 10 ? (
            <TableFilterNew columns={filterColumns} onSearch={onSearch} onReset={onResetFilter} />
          ) : (
            <div />
          )}
        </div>

        <div className={style.buttonOptions}>
          <NxtIconButton onClick={onNewUser} text="New User" isDisabled={!currGroupName} icon={<PersonAddOutlined />} />
        </div>
      </div>
      <CustomTable
        rowsPerPageOptions={[10, 25, 100]}
        totalCount={totalUserCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(pageNum) => setPage(pageNum)}
        onRowsPerPageChange={(rowPerPage) => setRowsPerPage(rowPerPage)}
      >
        <CustomTable.Header columns={USER_COLUMNS} onRequestSort={handleRequestSort} />

        <CustomTable.Body isLoading={isLoadingList} deviceData={paginatedList}>
          {paginatedList &&
            paginatedList.map((row) => {
              const { Username } = row;

              return (
                row && (
                  <TableRow key={Username}>
                    {userColumns.map((column, columnId) => {
                      const value = row[column.id];
                      return (
                        <TableBodyCell sx={styleRow(row.name, columnId)} key={column.id}>
                          {column.format ? column.format(value) : value}
                        </TableBodyCell>
                      );
                    })}
                    <TableBodyCell style={{ width: 30 }} sx={row.name === SUPPORT_USERNAME ? rightBorderColor : {}}>
                      {row.name === SUPPORT_USERNAME ? (
                        <UserPermissions
                          row={row}
                          isPermissionAllowed={isPermissionAllowed}
                          handleGrantSupport={handleGrantSupport}
                        />
                      ) : (
                        <Button onClick={(e) => onClickMenu(e, row)} style={{ color: theme.palette.common.black }}>
                          <MoreVertIcon />
                        </Button>
                      )}
                    </TableBodyCell>
                  </TableRow>
                )
              );
            })}
        </CustomTable.Body>
      </CustomTable>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={onDeleteUser}>Delete</MenuItem>
        <MenuItem onClick={showUpdateModal}>Edit</MenuItem>
      </Menu>
      <Dialog fullWidth maxWidth="xs" open={isOpenNewUserModal} onClose={onModalClose} disableEscapeKeyDown>
        <DialogTitle>New User</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <StyledTextField
              label="Full Name"
              {...register("userName", {
                required: "Full Name is required",
                maxLength: 50,
                validate: {
                  nameValidationCheck,
                },
              })}
              style={{ marginTop: 16 }}
              error={errors.userName}
            />
            {errors.userName && (
              <FieldError>
                {errors.userName.type === "maxLength"
                  ? "Length of User Name should not exceed 50."
                  : errors.userName.message}
              </FieldError>
            )}

            <Select
              name="role"
              label="Role"
              options={ROLE_OPTIONS}
              control={control}
              rules={{ required: "Role is required" }}
              error={errors.role}
            />
            {errors.role && <FieldError>{errors.role.message}</FieldError>}

            <StyledTextField
              label="Email"
              {...register("email", {
                required: "Email is required!",
                pattern: {
                  value: emailRegex,
                  message: "Entered value does not match email format!",
                },
              })}
              error={errors.email}
            />
            {errors.email && <FieldError>{errors.email.message}</FieldError>}

            <PhoneInputWrapper
              international
              inputComponent={CustomPhoneNumber}
              defaultCountry="US"
              countryCallingCodeEditable={false}
              value={phoneNum}
              onChange={(e) => {
                setPhoneNum(e);
              }}
            />
            {formState?.submitCount > 0 && (
              <FieldError>
                {phoneNum
                  ? isPossiblePhoneNumber(phoneNum)
                    ? undefined
                    : "Phone number is not valid!"
                  : "Phone number is required!"}
              </FieldError>
            )}

            <PageFooter>
              <PrimaryButton onClick={handleSubmit(onAddNewUser)} loading={isCreatingNewUser}>
                Create
              </PrimaryButton>
              <GhostButton onClick={() => setIsOpenNewUserModal(false)} sx={{ ml: 2 }}>
                Cancel
              </GhostButton>
            </PageFooter>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog fullWidth maxWidth="xs" open={isOpenUpdateUserModal} onClose={onUserModalClose} disableEscapeKeyDown>
        <DialogTitle>Update User {`"${currentRow?.name}"`}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Select
              name="role"
              label="Role"
              options={ROLE_OPTIONS}
              value={currentRowRole}
              control={control}
              rules={{ required: "Role is required" }}
              error={errors.role}
            />
            {errorsUpdate.role && <FieldError>{errorsUpdate.role.message}</FieldError>}

            <PhoneInputWrapper
              international
              inputComponent={CustomPhoneNumber}
              defaultCountry="US"
              countryCallingCodeEditable={false}
              value={phoneNum}
              onChange={(e) => {
                setPhoneNum(e);
              }}
            />
            {formStateUpdate?.submitCount > 0 && (
              <FieldError>
                {phoneNum
                  ? isPossiblePhoneNumber(phoneNum)
                    ? undefined
                    : "Phone number is not valid!"
                  : "Phone number is required!"}
              </FieldError>
            )}

            <PageFooter>
              <PrimaryButton onClick={handleSubmitUpdate(onUpdateUser)} loading={isUpdatingUser}>
                Update
              </PrimaryButton>
              <GhostButton onClick={() => setIsOpenUpdateUserModal(false)} sx={{ ml: 2 }}>
                Cancel
              </GhostButton>
            </PageFooter>
          </Box>
        </DialogContent>
      </Dialog>
      <Confirmation />

      <Popup title={modalMessage} isOpen={isLoadingUser}>
        <Spinner />
      </Popup>
    </>
  );
};

export default Users;
