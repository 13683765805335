import styled from "@emotion/styled";

const Wrapper = styled.div(({ position }) =>
  position === "absolute"
    ? {
        display: "flex",
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
      }
    : {},
);

export default Wrapper;
