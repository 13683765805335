import axiosInst from "./config/axiosConfig";

const updateMfaStatus = async (body) => axiosInst.put("/beta/mfaActionsUIHook", body);

const getUserGroup = async (body) => axiosInst.post("/beta/UserGroups", body);

const changePassword = async (body) => axiosInst.post("/beta/usersChangePwd", body);

const updateUserSetting = async (body) => axiosInst.post("/beta/usersUpdate", body);

export { updateMfaStatus, getUserGroup, changePassword, updateUserSetting };
