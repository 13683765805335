import React from "react";
import { Route } from "react-router-dom";

import Organizations from "pages/PowerUser/Organizations";
import Admins from "pages/PowerUser/Admins";
import PowerUsers from "pages/PowerUser/PowerUsers";
import Support from "pages/PowerUser/Support";
import PrivateRoute from "./components/PrivateRoute";

const PowerUserRoute = () => (
  <>
    <Route
      path="/orgs"
      element={
        <PrivateRoute>
          <Organizations />
        </PrivateRoute>
      }
    />
    <Route
      path="/admins"
      element={
        <PrivateRoute>
          <Admins />
        </PrivateRoute>
      }
    />
    <Route
      path="/power-users"
      element={
        <PrivateRoute>
          <PowerUsers />
        </PrivateRoute>
      }
    />
    <Route
      path="/support"
      element={
        <PrivateRoute>
          <Support />
        </PrivateRoute>
      }
    />
  </>
);

export default PowerUserRoute;
