import React, { useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { CircularProgress, Typography, useTheme } from "@mui/material";
import { NxtIconButton } from "components/common/nxt-icon-button/nxt-icon-button.component";
import LoadingSpinner from "components/LoadingSpinner";
import { PageSection, DetailWrapper, ActionBar } from "components/common/Wrapper";
import CustomTable from "components/table/table.component";
import { TableBodyCell } from "components/table/table.style";
import { useRequest } from "hooks/useRequest";
import ErrorScreen from "components/common/ErrorScreen";
import { convertIpAddress, storeActiveSerial } from "utils/util-functions";
import DetailGridComponent from "./DetailGrid.component";
import DetailForm from "./DeviceDetailForm.component";
import { GridActionRow, SectionHeader, SpinnerWrapper } from "./DeviceDetail.style";
import PENDING_GRID_COLUMNS, { ACTION_JSON } from "./DeviceDetail.config";
import DetailActions from "./DetailActions";
import { Popup } from "../../../components/common/popup/popup.component";
import { updateVpnDeviceStatus } from "../../../api/DeviceHelpers";
import { showWarning } from "../../../utils/notifications";

export const VPN_CONNECTED = "Connected";
export const VPN_CONNECTION_FAILED = "Connection Failed";
export const VPN_ERROR = "Error";

function DeviceDetail() {
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isLoadingEiInterfaces, setIsLoadingEiInterfaces] = useState(false);
  const [isControlLaunching, setIsControlLaunching] = useState(false);
  const [isVpnInitialized, setIsVpnInitialized] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const VPN_NOT_CONNECTED = "Not Connected";
  const { sn: serialNumber } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const {
    data: detailData,
    isLoading: isGettingDetail,
    mutate: mutateDetail,
    error: detailError,
  } = useRequest(`eiStatusUIHook?serialNumber=${serialNumber}`);

  // Initially load vpn data and check if 'vpnStatus' is 'Connected'.
  const { data: vpnData } = useRequest(`vpnUIHook?serialNumber=${serialNumber}`);

  const vpnConnectionOnClose = async () => {
    setIsControlLaunching(false);
  };

  const errorPopupOnClose = async () => {
    setIsError(false);
  };

  if (isControlLaunching && vpnData.vpnStatus === VPN_CONNECTED) {
    sessionStorage.setItem("serialNumber", serialNumber);
    navigate(`/launch-mission-control/${serialNumber}`);
    setIsControlLaunching(false);
    setIsVpnInitialized(false);
  }

  if (
    isControlLaunching &&
    isVpnInitialized &&
    (vpnData.vpnStatus === VPN_CONNECTION_FAILED || vpnData.vpnStatus === VPN_ERROR)
  ) {
    if (vpnData.vpnStatus === VPN_CONNECTION_FAILED) {
      showWarning("VPN connection could not be established. Please try later or contact support.");
    } else if (vpnData.vpnStatus === VPN_ERROR) {
      showWarning(
        "VPN connection could not be established. Please check for errors in the device system log or contact support.",
      );
    }
    setIsControlLaunching(false);
    setIsVpnInitialized(false);
  }

  const deviceDetail = useMemo(() => {
    if (detailData && detailData?.deviceData) {
      return {
        ...detailData.deviceData[0],
        lanIpAddress: convertIpAddress(detailData.deviceData[0].lanIpAddress || ""),
      };
    }
    return null;
  }, [detailData]);

  const {
    data: pendingActions,
    isLoading: isLoadingActions,
    mutate: mutatePendingActions,
    error: pendingActionError,
  } = useRequest(`pendingActionsUIHook?serialNumber=${serialNumber}`);

  const pendingActionsData = useMemo(
    () =>
      pendingActions?.map((item) => ({
        ...item,
        completed: item.completed ? "Yes" : "No",
      })),
    [pendingActions],
  );

  const goToLocation = () => {
    storeActiveSerial(serialNumber);
    navigate(`/map/${serialNumber}`);
  };

  const errorMessage = useMemo(() => {
    let message = "";

    if (!isLoadingActions && pendingActionError) {
      const { error: pendingErrorText } = pendingActionError.data;
      message += `${pendingErrorText}\n`;
    }

    if (!isGettingDetail && detailError) {
      const { error: detailErrorText } = detailError.data;
      message += detailErrorText;
    }

    return message ? `Errors occurred while calling the API:\n${message}` : null;
  }, [isLoadingActions, pendingActionError, isGettingDetail, detailError]);

  if (errorMessage) {
    return <ErrorScreen text={errorMessage} />;
  }

  const launchMissionControl = async () => {
    // If respoonse is 'Not Connected' call 'VPN Initialize API'.

    try {
      setIsControlLaunching(true);

      if ((vpnData.vpnStatus === VPN_NOT_CONNECTED || vpnData.vpnStatus === VPN_ERROR) && !isVpnInitialized) {
        await updateVpnDeviceStatus(serialNumber);
        setIsVpnInitialized(true);
      }

      if (vpnData.vpnStatus === VPN_CONNECTED) {
        setIsControlLaunching(false);
        setIsVpnInitialized(false);

        sessionStorage.setItem("serialNumber", serialNumber);
        navigate(`/launch-mission-control/${serialNumber}`);
      }
    } catch (error) {
      console.warn("vpnerror", error);
      setIsControlLaunching(false);
      setIsVpnInitialized(false);
      setIsError(true);
      setErrorText(error.message);
    }
  };

  const getColumnValue = (value, columnId) => {
    const actionValues = { value };

    // The pending action comes over as a long string. We break it up with the following
    if (columnId === ACTION_JSON) {
      const regex = /"[^"\\]*(?:\\[\s\S][^"\\]*)*"/g;
      const actionsArray = actionValues.value.match(regex);
      const actionIndex = actionsArray.indexOf("ei.action");
      const pendingAction = actionsArray[actionIndex + 2].replaceAll('"', "");

      return pendingAction;
    }
    return value;
  };

  return (
    <DetailWrapper>
      <ActionBar style={{ marginBottom: 0 }}>
        <NxtIconButton onClick={() => goToLocation()} text="Show Location" icon={<LocationOnIcon />} />
      </ActionBar>

      <PageSection>
        <DetailForm
          deviceDetail={deviceDetail}
          mutatePendingActions={mutatePendingActions}
          setIsLoadingDetail={setIsLoadingDetail}
          isLoadingDetail={isLoadingDetail || isGettingDetail}
          mutateDetail={mutateDetail}
        />
      </PageSection>

      <GridActionRow>
        <PageSection>
          <DetailGridComponent
            isLoadingDetail={isLoadingEiInterfaces || isGettingDetail}
            deviceDetail={deviceDetail}
            setIsLoadingDetail={setIsLoadingEiInterfaces}
            mutateDetail={mutateDetail}
          />

          {isGettingDetail && (
            <SpinnerWrapper>
              <LoadingSpinner />
            </SpinnerWrapper>
          )}
        </PageSection>

        <DetailActions
          launchMissionControl={launchMissionControl}
          deviceDetail={deviceDetail}
          mutatePendingActions={mutatePendingActions}
          mutateDetail={mutateDetail}
        />
      </GridActionRow>

      <PageSection>
        <SectionHeader>
          <span className="header-title">Pending Updates</span>
        </SectionHeader>

        <CustomTable>
          <CustomTable.Header columns={PENDING_GRID_COLUMNS} />

          <CustomTable.Body isLoading={isLoadingActions} deviceData={pendingActionsData}>
            {pendingActionsData?.map((row, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={`${row.eiSerialNumber}${i}`}>
                {PENDING_GRID_COLUMNS.map((column) => {
                  const value = getColumnValue(row[column.id], column.id);

                  return (
                    <TableBodyCell key={column.id} align={column.align} style={{ color: theme.palette.gray[500] }}>
                      {column.format && typeof value === "number" ? column.format(value) : value}
                    </TableBodyCell>
                  );
                })}
              </TableRow>
            ))}
          </CustomTable.Body>
        </CustomTable>
      </PageSection>

      {isControlLaunching && (
        <Popup
          title="Establishing VPN connection...Please wait."
          isOpen={isControlLaunching}
          onClick={vpnConnectionOnClose}
        >
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "32px" }}>
            <CircularProgress size={60} variant="indeterminate" />
          </div>
          <Typography>Note: This process could take up to a minute or more.</Typography>
        </Popup>
      )}

      {isError && (
        <Popup title="Error" isOpen={isError} onClick={errorPopupOnClose}>
          <Typography>{errorText}</Typography>
        </Popup>
      )}
    </DetailWrapper>
  );
}

export default DeviceDetail;
