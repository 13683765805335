import axiosInst from "./config/axiosConfig";

/**
 * 
 * @param {*} softwareUpdateObject {
    serialNumber: ""
    softwareVersion: ""
  }
 * @returns {
    serialNumber: ""
    softwareVersion: ""
   }
 */
async function updateEISoftwareHelper(softwareUpdateObject) {
  if (
    softwareUpdateObject.softwareVersion === undefined ||
    softwareUpdateObject.softwareVersion === null ||
    softwareUpdateObject.serialNumber === undefined ||
    softwareUpdateObject.serialNumber === null
  ) {
    return null;
  }

  return axiosInst
    .post("/beta/pendingActionsUIHook", {
      ...softwareUpdateObject,
    })
    .then((response) => response);
}

export default updateEISoftwareHelper;
