import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.gray[100]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotFoundText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 45px;
    margin-bottom: 0px;
  }

  p {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 20px;
    margin-top: 10px;
  }
`;

const NotFound = () => (
  <Wrapper>
    <NotFoundText>
      <h1>Page not found</h1>
      <p>The link you followed may be broken, or the page may have been removed.</p>
    </NotFoundText>
  </Wrapper>
);

export default NotFound;
