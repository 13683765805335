import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton } from "../Buttons";
import style from "./popup.module.css";

export function Popup({ isOpen, onClick, title, children }) {
  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}

        {onClick && (
          <div className={style.buttonContainer}>
            <PrimaryButton onClick={onClick}>Cancel</PrimaryButton>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

Popup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Popup.defaultProps = {
  onClick: null,
};
