import React from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

// Button designed for the map top bar which hasn't been implimented yet
export const NavButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border: none;
  cursor: pointer;
  padding: 6px 21px;
  border-radius: 20px;

  a {
    font-weight: 500;
    font-family: var(--font-primary);
    font-size: 17px;
    text-decoration: none;
    margin: 0;
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const TextButton = (props) => (
  <Button
    sx={{
      padding: "10px 20px",
      fontSize: "14px",
      textWrap: "noWrap",
      borderRadius: "50px",
      textTransform: "none",
      boxShadow: "none",
      height: "42px",
    }}
    variant="text"
    color="primary"
    {...props}
  />
);

export const PrimaryButton = (props) => (
  <Button
    style={{
      padding: "10px 40px",
      fontSize: "14px",
      textWrap: "nowrap",
      borderRadius: "50px",
      textTransform: "none",
      boxShadow: "none",
      height: "42px",
    }}
    variant="contained"
    color="primary"
    {...props}
  />
);

export const GhostButton = (props) => (
  <Button
    style={{
      padding: "10px 40px",
      fontSize: "14px",
      textWrap: "nowrap",
      borderRadius: "50px",
      textTransform: "none",
      boxShadow: "none",
      height: "42px",
      borderWidth: "2px",
    }}
    variant="outlined"
    color="primary"
    {...props}
  />
);

const StyledLoadingButton = styled(LoadingButton)`
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  font-family: var(--font-primary);
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 8px;
  text-transform: none;
`;

export const AuthButton = (props) => <StyledLoadingButton variant="contained" color="primary" {...props} />;
