import React, { useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SWRConfig } from "swr";

import AppRoute from "routes";
import { COLOR_MODE, REFRESH_INTERVAL } from "./utils/constants";
import { ContextProvider, GlobalContext } from "./context/GlobalContext";

const App = () => {
  const { colorMode } = useContext(GlobalContext);

  return (
    <div className="app-container">
      <SWRConfig value={{ refreshInterval: REFRESH_INTERVAL }}>
        <Router>
          <ContextProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <AppRoute />

              <ToastContainer
                position="top-right"
                autoClose={false} // persist notification
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme={colorMode === COLOR_MODE.LIGHT ? "light" : "dark"}
              />
            </LocalizationProvider>
          </ContextProvider>
        </Router>
      </SWRConfig>
    </div>
  );
};
export default App;
