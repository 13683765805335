import React, { useState, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { GhostButton, PrimaryButton } from "components/common/Buttons";
import { PageFooter } from "components/common/Wrapper";

const createPromise = () => {
  let resolver;

  return [
    new Promise((resolve) => {
      resolver = resolve;
    }),
    resolver,
  ];
};

const useConfirm = () => {
  const [open, setOpen] = useState(false);
  const [resolver, setResolver] = useState({ resolver: null });
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const getConfirmation = useCallback(async ({ title: newTitle, content: newContent }) => {
    setTitle(newTitle);
    setContent(newContent);
    setOpen(true);

    const [promise, resolve] = await createPromise();
    setResolver({ resolve });

    return promise;
  }, []);

  const onClick = async (status) => {
    setOpen(false);
    resolver.resolve(status);
  };

  const onModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const Confirmation = () => (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onModalClose} disableEscapeKeyDown>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div style={{ paddingBottom: "16px" }}>{content}</div>
        <PageFooter>
          <PrimaryButton onClick={() => onClick(true)}>Yes</PrimaryButton>
          <GhostButton onClick={() => onClick(false)} sx={{ ml: 2 }}>
            No
          </GhostButton>
        </PageFooter>
      </DialogContent>
    </Dialog>
  );

  return [getConfirmation, Confirmation];
};

export default useConfirm;
