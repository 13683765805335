import { useEffect, useState } from "react";
import { getOrganizationList } from "api/PowerUserHelpers";

const useOrganizations = (username, reloadOrg) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orgList, setOrgList] = useState([]);

  const fetchOrganizations = async (token) => {
    try {
      setIsLoading(true);
      const { data } = await getOrganizationList({ username, token });
      if (token) {
        setOrgList((value) => [...value, ...(data.Groups || [])]);
      } else {
        setOrgList(data.Groups);
      }

      if (data.NextToken) {
        await fetchOrganizations(data.NextToken);
      }
    } catch (error) {
      // error handling
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (reloadOrg) {
      fetchOrganizations();
    }
  }, [reloadOrg]);

  return { isLoading, orgList };
};

export default useOrganizations;
