import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import { useTheme } from "@mui/material";
import EastIcon from "@mui/icons-material/East";

import { FieldError } from "components/common/Input";
import { HeaderTitle } from "components/common/Text";
import { DetailWrapper, PageSection, PageHeader, PageFooter } from "components/common/Wrapper";
import CustomTable from "components/table";
import { PrimaryButton, GhostButton } from "components/common/Buttons";
import { showError, showSuccess } from "utils/notifications";
import { updateDevice } from "api/DeviceHelpers";
import { TableBodyCell } from "components/table/table.style";
import { useRequest } from "hooks/useRequest";
import Select from "components/common/Select";
import POOL_DEVICE_COLS from "./PoolDetail.config";

const PoolDetail = () => {
  const { poolId } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [isTransforming, setIsTransforming] = useState(false);
  const open = Boolean(anchorEl);
  const [isOpenTransferModal, setIsOpenTransferModal] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const startIndex = useMemo(() => rowsPerPage * page, [rowsPerPage, page]);
  const endIndex = useMemo(() => startIndex + rowsPerPage - 1, [startIndex, rowsPerPage]);
  const { data, isLoading, mutate } = useRequest(
    `devices?devicePool=${poolId}&firstDeviceIndex=${startIndex}&lastDeviceIndex=${endIndex}`,
  );
  const totalDeviceCount = useMemo(() => data?.totalDevicesInPool || 0, [data]);
  const deviceList = useMemo(() => data?.deviceData || [], [data]);

  const { data: poolsData } = useRequest("devicePools");
  const poolList = useMemo(
    () => (poolsData?.length > 0 ? poolsData.filter(({ devicePool }) => devicePool !== poolId) : []),
    [poolsData, poolId],
  );
  const poolOptions = useMemo(
    () => poolList.map(({ devicePool }) => ({ value: devicePool, label: devicePool })),
    [poolList],
  );

  const bodyColumns = useMemo(() => {
    const tempCols = [...POOL_DEVICE_COLS];
    tempCols.pop();
    return tempCols;
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onClickMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const transferDevice = () => {
    setAnchorEl(null);
    setIsOpenTransferModal(true);
  };

  const onModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setIsOpenTransferModal(false);
    }
  };

  const onConfirmTransfer = async ({ devicePool }) => {
    if (!devicePool) {
      showError("Device pool is not valid!");
      return;
    }

    setIsTransforming(true);

    try {
      // call PUT /devices API
      const result = await updateDevice({ serialNumber: currentRow.serialNumber, devicePool });

      if (result.data) {
        // close modal & show alert
        setIsOpenTransferModal(false);
        showSuccess(`Success to transfer a device ${currentRow.serialNumber} to ${devicePool} pool!`);

        // Refresh table
        mutate();
      }
    } catch (error) {
      // error handling
    } finally {
      setIsTransforming(false);
    }
  };

  const onClickSN = (serialNumber) => {
    navigate(`/devices/${serialNumber}`);
  };

  return (
    <DetailWrapper>
      <PageSection>
        <PageHeader>
          <HeaderTitle>{`Devices in Pool '${poolId}'`}</HeaderTitle>
        </PageHeader>

        <CustomTable
          rowsPerPageOptions={[10, 25, 100]}
          totalCount={totalDeviceCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={(pageNum) => setPage(pageNum)}
          onRowsPerPageChange={(rowPerPage) => setRowsPerPage(rowPerPage)}
        >
          <CustomTable.Header columns={POOL_DEVICE_COLS} />

          <CustomTable.Body isLoading={isLoading} deviceData={deviceList}>
            {deviceList &&
              deviceList.map((row) => (
                <TableRow key={row.serialNumber}>
                  {bodyColumns.map((column) => {
                    const value = row[column.id];

                    if (column.id !== "serialNumber") {
                      return <TableBodyCell key={column.id}>{value}</TableBodyCell>;
                    }

                    return (
                      <TableBodyCell key={column.id} onClick={() => onClickSN(value)} style={{ cursor: "pointer" }}>
                        <span>{value}</span>
                        <EastIcon style={{ width: 16, height: 14, marginLeft: 7 }} />
                      </TableBodyCell>
                    );
                  })}

                  <TableBodyCell style={{ width: 30 }}>
                    <Button onClick={(e) => onClickMenu(e, row)} style={{ color: theme.palette.common.black }}>
                      <MoreVertIcon />
                    </Button>
                  </TableBodyCell>
                </TableRow>
              ))}
          </CustomTable.Body>
        </CustomTable>
      </PageSection>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={transferDevice}>Transfer</MenuItem>
      </Menu>

      <Dialog fullWidth maxWidth="xs" open={isOpenTransferModal} onClose={onModalClose} disableEscapeKeyDown>
        <DialogTitle>Transfer Device</DialogTitle>
        <DialogContent>
          <div>{`Please select a pool to transfer device '${currentRow?.serialNumber}'.`}</div>

          <form>
            <Select
              name="devicePool"
              options={poolOptions}
              control={control}
              rules={{ required: "Device Pool is required" }}
              error={errors.devicePool}
            />
            {errors.devicePool && <FieldError>{errors.devicePool.message}</FieldError>}

            <PageFooter>
              <PrimaryButton loading={isTransforming} onClick={handleSubmit(onConfirmTransfer)}>
                Save
              </PrimaryButton>
              <GhostButton onClick={() => setIsOpenTransferModal(false)} sx={{ ml: 2 }}>
                Cancel
              </GhostButton>
            </PageFooter>
          </form>
        </DialogContent>
      </Dialog>
    </DetailWrapper>
  );
};

export default PoolDetail;
