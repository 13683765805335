import MUISelect from "@mui/material/Select";
import styled from "@emotion/styled";

const StyledSelect = styled(MUISelect)`
  .MuiSelect-select {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
  }
`;

export default StyledSelect;
