import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router";
import styled from "@emotion/styled";

import { isSessionValid } from "pages/Auth/Cogcognito";
import { useAuth } from "hooks/useProvideAuth";
import { showError } from "utils/notifications";

const PublicRouteWrapper = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.gray[100]};
`;

const PublicRoute = ({ children }) => {
  const isAuthenticated = isSessionValid();
  const { refetch } = useAuth();
  const [userType, setUserType] = useState();

  useEffect(() => {
    if (isAuthenticated) {
      refetch?.()
        .then(({ isAdmin, isPowerUser }) => {
          setUserType({ isAdmin, isPowerUser });
        })
        .catch((error) => {
          showError(error.toString());
        });
    }
  }, []);

  return isAuthenticated ? (
    !!userType && <Navigate to={userType.isPowerUser ? "/orgs" : userType.isAdmin ? "/users" : "/devices"} />
  ) : (
    <PublicRouteWrapper>{children}</PublicRouteWrapper>
  );
};
PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicRoute;
