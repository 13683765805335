export const POOL_LIST_PAGE_SIZE = 10;

export const DEVICE_LIST_PAGE_SIZE = 6;

export const KEY_FIELD_IDS = {
  SERIAL_NO: "serialNumber", // This is an actual field name on API response
  PHONE_NUMBER: "PhoneNumber", // This is an actual field name on API response
  DEVICE_ALIAS: "deviceAlias", // This is an actual field name on API response
};

export const MISSING_DESC = {
  PhoneNumber: "There is no phone number related to the device, please sort by Serial Number.",
  deviceAlias: "There is no alias name related to the device, please sort by Serial Number.",
};

export const AVAILABLE_KEY_FIELDS = [
  {
    id: KEY_FIELD_IDS.SERIAL_NO,
    label: "Serial Number",
  },
  {
    id: KEY_FIELD_IDS.PHONE_NUMBER,
    label: "SIM Phone No",
  },
  {
    id: KEY_FIELD_IDS.DEVICE_ALIAS,
    label: "Device Alias",
  },
];
